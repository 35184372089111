import { Selector } from "zeus"

export const solutionsQuery = Selector("BaseSolution")({
    uuid: true,
    candidate: { email: true, firstName: true, lastName: true, phoneNumber: true, status: true, uuid: true },
    score: true,
    timeSpent: true,
    starred: true,
    submittedAt: true,
    seenAt: true,
    summary: {
        numberOfQuestions: true,
        score: true,
        competency: {
            uuid: true,
            name: true,
        },
    },
})

export const testQuerry = Selector("Test")({
    uuid: true,
    testType: true,
    availableTime: true,
    organizationName: true,
    createdAt: true,
    questions: {
        uuid: true,
        content: true,
        difficulty: true,
        availableTime: true,
        competency: {
            uuid: true,
            name: true,
            description: true,
            domain: true,
            icon: true,
        },
    },
    description: true,
    roleName: true,
    seniority: true,
    testState: true,
    title: true,
    competencies: {
        uuid: true,
        name: true,
        description: true,
        domain: true,
        icon: true,
    },
    assignedTo: {
        uuid: true,
        firstName: true,
        lastName: true,
        organizationName: true,
    },
    averageSummary: {
        competency: {
            uuid: true,
            name: true,
        },
        numberOfQuestions: true,
        score: true,
    },
    solutionsDistribution: {
        label: true,
        value: true,
    },
    averageSolutionTime: true,
    averageSolutionScore: true,
    completedBy: true,
})

export const solutionDetailsQuery = Selector("Solution")({
    candidate: { firstName: true, lastName: true, email: true, phoneNumber: true },
    uuid: true,
    starred: true,
    createdAt: true,
    score: true,
    submittedAt: true,
    seenAt: true,
    timeSpent: true,
    solutionState: true,
    testRoleName: true,
    summary: {
        competency: { name: true, uuid: true },
        numberOfQuestions: true,
        score: true,
    },
    test: {
        uuid: true,
        availableTime: true,
        roleName: true,
        title: true,
        createdAt: true,
        testType: true,
        solutionsDistribution: {
            label: true,
            value: true,
        },
    },
})

export const userDataQuery = Selector("BaseUser")({
    email: true,
    accountId: true,
    firstName: true,
    lastName: true,
    isAdmin: true,
    uuid: true,
    organizationName: true,
    locale: true,
    role: true,
})

export const useSkillSelector = Selector("Competency")({
    name: true,
    domain: true,
    uuid: true,
    description: true,
    icon: true,
})

export const allTestsSelector = Selector("BaseTest")({
    uuid: true,
    testState: true,
    createdAt: true,
    mostRecentActivityAt: true,
    mostRecentActivitySecondsAgo: true,
    roleName: true,
    title: true,
    typeformTestId: true,
    completedBy: true,
    testType: true,
})

export const updateCandidatesStatusSelector = Selector("UpdateCandidatesStatusInput")({
    candidateIds: true,
    status: true,
})
