import { useEffect, useState } from "react"
import { Breakpoint } from "@/styles/theme"
import { Grid } from "antd"
import { throttle } from "lodash"

export const IMPERSONATOR_BAR_HEIGHT = 40

const _useBreakpoint = Grid.useBreakpoint

export const useBreakpoint = () => {
    const breakpoint = _useBreakpoint()
    return breakpoint
}

const getDeviceConfig = (width: number) => {
    if (width >= 1440) {
        return "lg"
    } else if (width >= 1024) {
        return "md"
    } else if (width >= 375) {
        return "sm"
    } else {
        return "sx"
    }
}

export type ScreenSizes = "sm" | "md" | "lg" | "sx"

export const useNewBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState<ScreenSizes>(getDeviceConfig(window.innerWidth))

    useEffect(() => {
        const calcInnerWidth = throttle(() => {
            setBrkPnt(getDeviceConfig(window.innerWidth))
        }, 200)
        window.addEventListener("resize", calcInnerWidth)
        return () => window.removeEventListener("resize", calcInnerWidth)
    }, [])

    return brkPnt
}

export const isMobile = (breakpoint: Partial<Record<Breakpoint, boolean>>) => breakpoint.md === false

export const isMediumScreen = (breakpoint: Partial<Record<Breakpoint, boolean>>) => breakpoint.xl === false
