import _styled, {
    createGlobalStyle as _createGlobalStyle,
    CSSObject,
    GlobalStyleComponent,
    Interpolation,
    InterpolationFunction,
    ThemedStyledInterface,
    ThemedStyledProps,
    useTheme as _useTheme,
} from "styled-components"

import { Theme } from "./theme"

export const styled = _styled as any as ThemedStyledInterface<Theme>

export const createGlobalStyle = _createGlobalStyle as any as (
    first: TemplateStringsArray | CSSObject | InterpolationFunction<ThemedStyledProps<void, Theme>>,
    ...interpolations: Array<Interpolation<ThemedStyledProps<void, Theme>>>
) => GlobalStyleComponent<void, Theme>

export const useTheme = _useTheme as any as () => Theme
