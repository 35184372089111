import ArrowRight from "@/assets/icons/ArrowRight.svg"
import CheckCircle from "@/assets/icons/CheckCircle.svg"
import Close from "@/assets/icons/Close.svg"
import LoadingSpinnerOnDarkBackground from "@/assets/icons/LoadingSpinnerOnDarkBackground.svg"
import LoadingSpinnerOnLightBackground from "@/assets/icons/LoadingSpinnerOnLightBackground.svg"
import Logo from "@/assets/icons/Logo.svg"
import Plus from "@/assets/icons/Plus.svg"
import Timer from "@/assets/icons/Timer.svg"

export const ICONS = {
    ArrowRight,
    Plus,
    Logo,
    Timer,
    LoadingSpinnerOnLightBackground,
    LoadingSpinnerOnDarkBackground,
    Close,
    CheckCircle,
}
