import { useEffect, useState } from "react"
import { HR_APP_URL } from "@/constants"
import { identifyUser } from "@/gtm"
import i18n from "@/i18n"
import { paths } from "@/paths"
import {
    getAuthToken,
    removeAuthToken,
    removeImpersonatorToken,
    scalars,
    setAuthToken,
    typedQuery,
    userDataQuery,
} from "@/utils"
import { gql, useApolloClient, useQuery } from "@apollo/client"
import { useRouter } from "next/router"
import { createContainer } from "unstated-next"
import { FromSelector, Locale } from "zeus"

import { getImpersonatorToken, setImpersonatorToken } from "./../utils/auth"

export type UserData = FromSelector<typeof userDataQuery, "BaseUser", typeof scalars>

export const HeroEvent = {
    hrTests: "Tests Page View",
    hrTest: "Test Page View",
    hrTestSolution: "Solution Page View",
    hrNewTestStep1: "New Test Page View",
    hrNewTestStep2: "New Test Competencies ",
    hrNewTestStep3: "New Test Questions",
    hrNewTestStep4: "New Test Created",
}

const useAuthContainer = createContainer(() => {
    const router = useRouter()
    const client = useApolloClient()
    const [isLogged, setIsLogged] = useState<"unknown" | "yes" | "no">("unknown")
    const [isImpersonating, setIsImpersonating] = useState(false)
    const [userType, setUserType] = useState<"admin" | "hr" | "candidate">("candidate")
    const [userData, setUserData] = useState<UserData | undefined>()
    const [urlBeforeLoginRedirect, setUrlBeforeLoginRedirect] = useState<string>()

    const { loading: loadingUserData, refetch: refetchUserData } = useQuery(
        typedQuery({ me: userDataQuery }, { operationName: "Me" }),
        {
            skip: isLogged !== "yes",
            onCompleted: async ({ me }) => {
                if (me) {
                    identifyUser(me)
                    me.isAdmin ? setUserType("admin") : setUserType("hr")
                    setUserData(me)
                    me.locale === Locale.PL ? i18n.changeLanguage("pl") : i18n.changeLanguage("en")
                }
            },
            onError: () => {
                logout()
            },
        }
    )

    const hero = (eventName: keyof typeof HeroEvent, properties?: Record<string, unknown>) => {
        client.mutate({
            mutation: gql`
                mutation addHero($eventName: String!, $properties: JSONString) {
                    hero(input: { eventName: $eventName, properties: $properties }) {
                        result
                    }
                }
            `,
            variables: { eventName: HeroEvent[eventName], properties: properties && JSON.stringify(properties) },
        })
    }

    const logout = () => {
        removeAuthToken()
        setUserData(undefined)
        setIsLogged("no")
        setUserType("candidate")
        removeImpersonatorToken()
    }

    const login = (token: string) => {
        setAuthToken(token)
        setIsLogged("yes")
    }

    const stopImpersonating = async () => {
        removeImpersonatorToken()
        setIsImpersonating(false)
        await refetchUserData()
        router.push(paths.adminTests.go())
    }

    const startImpersonatingTest = (token: string, testId: string) => {
        setImpersonatorToken(token)
        window.open(`${HR_APP_URL}/hr/test?id=${testId}`)
    }
    const startImpersonatingUser = (token: string) => {
        setImpersonatorToken(token)
        window.open(`${HR_APP_URL}/hr/tests`)
    }

    useEffect(() => {
        const cookiesToken = getAuthToken()
        if (cookiesToken && cookiesToken.length > 0) {
            setIsLogged("yes")
        } else {
            setIsLogged("no")
        }
    }, [])

    useEffect(() => {
        const impToken = getImpersonatorToken()
        if (impToken && impToken.length > 0 && router.isReady && router.pathname.includes(`/hr/`)) {
            setIsImpersonating(true)
        } else {
            setIsImpersonating(false)
        }
    }, [router, router.isReady, router.pathname])

    return {
        isLogged,
        userType,
        loadingUserData,
        userData,
        setUserType,
        login,
        logout,
        urlBeforeLoginRedirect,
        setUrlBeforeLoginRedirect,
        stopImpersonating,
        startImpersonatingTest,
        startImpersonatingUser,
        isImpersonating,
        hero,
    }
})

export const AuthProvider = useAuthContainer.Provider
export const useAuth = useAuthContainer.useContainer
