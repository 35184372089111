import { ICONS } from "./icons"

export type IconName = keyof typeof ICONS

export type IconProps = {
    name: IconName
    className?: string
}

export const Icon = ({ name, className }: IconProps) => {
    const Component = ICONS[name]

    return <Component className={className} />
}
