import Cookies from "js-cookie"

export const AUTH_TOKEN_KEY = "authToken"
export const IMPERSONATOR_AUTH_TOKEN_KEY = "impersonatorToken"

export const getTokenToClient = (): string | undefined => {
    const token = Cookies.get(AUTH_TOKEN_KEY) as string | undefined
    const impersonatorToken = Cookies.get(IMPERSONATOR_AUTH_TOKEN_KEY) as string | undefined

    return impersonatorToken && window && window.location.href.includes("/hr/") ? impersonatorToken : token
}

export const getAuthToken = (): string | undefined => {
    return Cookies.get(AUTH_TOKEN_KEY) as string | undefined
}

export const getImpersonatorToken = (): string | undefined => {
    return Cookies.get(IMPERSONATOR_AUTH_TOKEN_KEY) as string | undefined
}

export const setAuthToken = (token: string) => {
    Cookies.set(AUTH_TOKEN_KEY, token)
}

export const removeAuthToken = () => {
    Cookies.remove(AUTH_TOKEN_KEY)
}

export const removeImpersonatorToken = () => {
    Cookies.remove(IMPERSONATOR_AUTH_TOKEN_KEY)
}

export const setImpersonatorToken = (token: string) => {
    Cookies.set(IMPERSONATOR_AUTH_TOKEN_KEY, token)
}

export type SecureProps = { restrictredUserTypes: ("candidate" | "hr")[] }
