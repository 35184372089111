/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';
export const HOST = "http://localhost:8000/graphql"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })('Query', response, ['Query']);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])]));
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) =>
        ibb(k, k, v, [...p, purifyGraphQLKey(keyName || k)], [...pOriginals, purifyGraphQLKey(originalKey)], false),
      )
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : Record<string, unknown>
        : never;
    }[keyof DST] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = GraphQLTypes["BaseTestQuestion"] | GraphQLTypes["BaseTestQuestionWithAnswers"]
export type ScalarCoders = {
	UUID?: ScalarResolver;
	DateTime?: ScalarResolver;
	Decimal?: ScalarResolver;
	JSONString?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["TestPreviewResult"] | GraphQLTypes["TestQuestion"]

export type ValueTypes = {
    ["RootQuery"]: AliasType<{
adminTestConnection?: [{	search?: string | undefined | null | Variable<any, string>,	orderBy?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	descending?: boolean | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["AdminTestConnection"]],
adminQuestionConnection?: [{	search?: string | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["AdminQuestionConnection"]],
adminUserConnection?: [{	search?: string | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["AdminUserConnection"]],
adminSolutionConnection?: [{	search?: string | undefined | null | Variable<any, string>,	ids?: Array<ValueTypes["UUID"] | undefined | null> | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["AdminSolutionConnection"]],
adminTest?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["AdminTest"]],
adminUser?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["AdminBaseUser"]],
adminQuestion?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Question"]],
	hello?:boolean | `@${string}`,
error?: [{	culprit?: string | undefined | null | Variable<any, string>},boolean | `@${string}`],
competencyConnection?: [{	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["CompetencyConnection"]],
testConnection?: [{	search?: string | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["TestConnection"]],
solutionConnection?: [{	search?: string | undefined | null | Variable<any, string>,	ids?: Array<ValueTypes["UUID"] | undefined | null> | undefined | null | Variable<any, string>,	orderScoreByCompetency?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	orderBy?: Array<string | undefined | null> | undefined | null | Variable<any, string>,	descending?: boolean | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["SolutionConnection"]],
testSolutionConnection?: [{	testId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["SolutionConnection"]],
solution?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Solution"]],
test?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Test"]],
candidate?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Candidate"]],
candidateTestPreview?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["TestPreviewResult"]],
candidateSolutionPreview?: [{	/** Candidate's session token. */
	sessionToken: string | Variable<any, string>},ValueTypes["CandidateSolutionPreview"]],
	me?:ValueTypes["BaseUser"],
candidatePreview?: [{	/** Candidate's session token. */
	sessionToken: string | Variable<any, string>},ValueTypes["CandidatePreview"]],
suggestedCompetencies?: [{	position?: ValueTypes["PositionInput"] | undefined | null | Variable<any, string>},ValueTypes["SuggestedCompetencies"]],
supportedCompetencies?: [{	position: ValueTypes["PositionInput"] | Variable<any, string>,	offset?: number | undefined | null | Variable<any, string>,	before?: string | undefined | null | Variable<any, string>,	after?: string | undefined | null | Variable<any, string>,	first?: number | undefined | null | Variable<any, string>,	last?: number | undefined | null | Variable<any, string>},ValueTypes["CompetencyConnection"]],
suggestedOpenQuestions?: [{	input?: Array<ValueTypes["WizardCompetencyInput"] | undefined | null> | undefined | null | Variable<any, string>},ValueTypes["SuggestedOpenQuestions"]],
		__typename?: boolean | `@${string}`
}>;
	["AdminTestConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["AdminTestEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: AliasType<{
	/** When paginating forwards, are there more items? */
	hasNextPage?:boolean | `@${string}`,
	/** When paginating backwards, are there more items? */
	hasPreviousPage?:boolean | `@${string}`,
	/** When paginating backwards, the cursor to continue. */
	startCursor?:boolean | `@${string}`,
	/** When paginating forwards, the cursor to continue. */
	endCursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminTest` and its cursor. */
["AdminTestEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["AdminBaseTest"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminBaseTest"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ValueTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	assignedTo?:ValueTypes["AdminBaseUser"],
	solutionsDistribution?:ValueTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questionsNo?:boolean | `@${string}`,
	totalTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]:unknown;
	["Competency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Icon"]:Icon;
	/** An enumeration. */
["Locale"]:Locale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]:unknown;
	/** An enumeration. */
["Seniority"]:Seniority;
	/** An enumeration. */
["TestState"]:TestState;
	/** An enumeration. */
["TestType"]:TestType;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]:unknown;
	["AdminBaseUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	isAdmin?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	logUrl?:boolean | `@${string}`,
	accountLogUrl?:boolean | `@${string}`,
	blocked?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["RecruiterRole"]:RecruiterRole;
	["Bin"]: AliasType<{
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminQuestionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["AdminQuestionEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminQuestion` and its cursor. */
["AdminQuestionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["Question"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Question"]: AliasType<{
	uuid?:boolean | `@${string}`,
	competency?:ValueTypes["Competency"],
	custom?:boolean | `@${string}`,
	questionType?:boolean | `@${string}`,
	answers?:ValueTypes["Answer"],
	content?:boolean | `@${string}`,
	appendix?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	difficulty?:boolean | `@${string}`,
	questionStatus?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["QuestionType"]:QuestionType;
	["Answer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	correct?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Difficulty"]:Difficulty;
	/** An enumeration. */
["QuestionStatus"]:QuestionStatus;
	["AdminUserConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["AdminUserEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminUser` and its cursor. */
["AdminUserEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["BaseUser"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	isAdmin?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	logUrl?:boolean | `@${string}`,
	accountLogUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminSolutionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["AdminSolutionEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminSolution` and its cursor. */
["AdminSolutionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["Solution"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Solution"]: AliasType<{
	candidate?:ValueTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ValueTypes["SummaryEntry"],
	test?:ValueTypes["BaseTest"],
	responses?:ValueTypes["Response"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionCandidate"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["CandidateStatus"]:CandidateStatus;
	/** An enumeration. */
["SolutionState"]:SolutionState;
	["SummaryEntry"]: AliasType<{
	competency?:ValueTypes["SummaryCompetency"],
	numberOfQuestions?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummaryCompetency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseTest"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ValueTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	/** Owner of the test (HR user / organization) */
	assignedTo?:ValueTypes["BaseUser"],
	solutionsDistribution?:ValueTypes["Bin"],
	experimental?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Response"]: AliasType<{
	uuid?:boolean | `@${string}`,
	index?:boolean | `@${string}`,
	question?:ValueTypes["Question"],
	answersGiven?:ValueTypes["AnswerGiven"],
	score?:boolean | `@${string}`,
	outdated?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AnswerGiven"]: AliasType<{
	uuid?:boolean | `@${string}`,
	answer?:ValueTypes["Answer"],
	correct?:boolean | `@${string}`,
	userSelected?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminTest"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ValueTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	assignedTo?:ValueTypes["AdminBaseUser"],
	solutionsDistribution?:ValueTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questionsNo?:boolean | `@${string}`,
	totalTime?:boolean | `@${string}`,
	questions?:ValueTypes["Question"],
	averageSummary?:ValueTypes["SummaryEntry"],
	solutionUuids?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompetencyConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["CompetencyEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Competency` and its cursor. */
["CompetencyEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["Competency"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["TestEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Test` and its cursor. */
["TestEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["BaseTest"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ValueTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ValueTypes["SolutionEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ValueTypes["BaseSolution"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseSolution"]: AliasType<{
	candidate?:ValueTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ValueTypes["SummaryEntry"],
		__typename?: boolean | `@${string}`
}>;
	["Test"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ValueTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	/** Owner of the test (HR user / organization) */
	assignedTo?:ValueTypes["BaseUser"],
	solutionsDistribution?:ValueTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questions?:ValueTypes["Question"],
	averageSummary?:ValueTypes["SummaryEntry"],
	solutionUuids?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Candidate"]: AliasType<{
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	disabled?:boolean | `@${string}`,
	assessments?:ValueTypes["Assessment"],
	solutions?:ValueTypes["BaseSolution"],
		__typename?: boolean | `@${string}`
}>;
	["Assessment"]: AliasType<{
	candidate?:ValueTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ValueTypes["SummaryEntry"],
	test?:ValueTypes["BaseTest"],
	responses?:ValueTypes["Response"],
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewResult"]: AliasType<{		["...on TestPreview"] : ValueTypes["TestPreview"],
		["...on TestPreviewUnavailable"] : ValueTypes["TestPreviewUnavailable"]
		__typename?: boolean | `@${string}`
}>;
	["TestPreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ValueTypes["Competency"],
	locale?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewUnavailable"]: AliasType<{
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSolutionPreview"]: AliasType<{
	test?:ValueTypes["TestPreview"],
	solutionState?:boolean | `@${string}`,
	candidate?:ValueTypes["CandidatePreview"],
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ValueTypes["SummaryEntry"],
		__typename?: boolean | `@${string}`
}>;
	["CandidatePreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuggestedCompetencies"]: AliasType<{
	selected?:ValueTypes["SuggestedCompetency"],
		__typename?: boolean | `@${string}`
}>;
	["SuggestedCompetency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PositionInput"]: {
	jobTitle: string | Variable<any, string>,
	seniority?: ValueTypes["Seniority"] | undefined | null | Variable<any, string>,
	testLevel: ValueTypes["TestLevel"] | Variable<any, string>,
	testType: ValueTypes["TestType"] | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	/** An enumeration. */
["TestLevel"]:TestLevel;
	["SuggestedOpenQuestions"]: AliasType<{
	suggested?:ValueTypes["SuggestedOpenQuestion"],
		__typename?: boolean | `@${string}`
}>;
	["SuggestedOpenQuestion"]: AliasType<{
	competencyName?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WizardCompetencyInput"]: {
	id: ValueTypes["UUID"] | Variable<any, string>
};
	["MutationsRoot"]: AliasType<{
hero?: [{	input: ValueTypes["TrackInput"] | Variable<any, string>},ValueTypes["HeroTracking"]],
test?: [{	name: string | Variable<any, string>},ValueTypes["TestMutation"]],
auth?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>},ValueTypes["Auth"]],
resetPassword?: [{	password: string | Variable<any, string>,	resetToken: string | Variable<any, string>},ValueTypes["ResetPassword"]],
adminCreateTest?: [{	availableTime: number | Variable<any, string>,	description: string | Variable<any, string>,	experimental?: boolean | undefined | null | Variable<any, string>,	questions: Array<ValueTypes["UUID"] | undefined | null> | Variable<any, string>,	roleName: string | Variable<any, string>,	seniority: ValueTypes["Seniority"] | Variable<any, string>,	testType: ValueTypes["TestType"] | Variable<any, string>,	title: string | Variable<any, string>,	user?: ValueTypes["UUID"] | undefined | null | Variable<any, string>},ValueTypes["CreateTest"]],
adminUpdateTest?: [{	availableTime?: number | undefined | null | Variable<any, string>,	description?: string | undefined | null | Variable<any, string>,	experimental?: boolean | undefined | null | Variable<any, string>,	questions?: Array<ValueTypes["UUID"] | undefined | null> | undefined | null | Variable<any, string>,	roleName?: string | undefined | null | Variable<any, string>,	seniority?: ValueTypes["Seniority"] | undefined | null | Variable<any, string>,	testType?: ValueTypes["TestType"] | undefined | null | Variable<any, string>,	testUuid: ValueTypes["UUID"] | Variable<any, string>,	title?: string | undefined | null | Variable<any, string>},ValueTypes["UpdateTest"]],
adminPublishTest?: [{	testUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["PublishTest"]],
adminDisableTest?: [{	testUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["DisableTest"]],
adminEnableTest?: [{	testUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["EnableTest"]],
adminDeleteTest?: [{	testUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["DeleteTest"]],
adminDuplicateTest?: [{	newUser?: ValueTypes["UUID"] | undefined | null | Variable<any, string>,	testUuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["DuplicateTest"]],
adminCreateUser?: [{	email: string | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>,	locale: ValueTypes["Locale"] | Variable<any, string>,	organizationName?: string | undefined | null | Variable<any, string>},ValueTypes["CreateUser"]],
adminUpdateUser?: [{	email: string | Variable<any, string>,	firstName: string | Variable<any, string>,	id: ValueTypes["UUID"] | Variable<any, string>,	lastName: string | Variable<any, string>,	locale: ValueTypes["Locale"] | Variable<any, string>,	organizationName?: string | undefined | null | Variable<any, string>},ValueTypes["UpdateUser"]],
adminDeleteUser?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["DeleteUser"]],
adminToggleBlockUser?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["ToggleBlockUser"]],
adminTriggerPasswordReset?: [{	uuid: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["TriggerPasswordReset"]],
adminImpersonateUser?: [{	/** Uuid of the user to impersonate */
	userId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["ImpersonateUser"]],
candidateIssue2faToken?: [{	/** Phone number to verify by SMS code. */
	phoneNumber: string | Variable<any, string>,	/** Public id of the test being attempted. */
	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Issue2FAToken"]],
candidateGet2faToken?: [{	/** Phone number to which the verification token was sent. */
	phoneNumber: string | Variable<any, string>,	/** Public id of the test being attempted. */
	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["Get2FAToken"]],
candidateVerify2faToken?: [{	/** Phone number to which the verification token was sent. */
	phoneNumber: string | Variable<any, string>,	/** Public id of the test being attempted. */
	testId: ValueTypes["UUID"] | Variable<any, string>,	/** Verification token sent to candidate via SMS. */
	verificationToken: string | Variable<any, string>},ValueTypes["Verify2FAToken"]],
registerCandidate?: [{	/** Candidate's email. */
	email: string | Variable<any, string>,	/** Candidate's first name. */
	firstName: string | Variable<any, string>,	/** Intermediate token received after successful phone number verification. */
	intermediateToken: string | Variable<any, string>,	/** Candidate's last name. */
	lastName: string | Variable<any, string>},ValueTypes["RegisterCandidate"]],
candidateAttemptSolution?: [{	/** Candidate's session token. */
	candidateSessionToken: string | Variable<any, string>},ValueTypes["CandidateAttemptSolution"]],
candidateGiveFeedback?: [{	/** Candidate's session token. */
	candidateSessionToken: string | Variable<any, string>,	/** Feedback score given by a candidate. */
	feedbackScore?: ValueTypes["Decimal"] | undefined | null | Variable<any, string>,	/** Feedback score type given by a candidate. */
	feedbackScoreVersion?: string | undefined | null | Variable<any, string>,	/** Text feedback given by a candidate. */
	feedbackText?: string | undefined | null | Variable<any, string>},ValueTypes["CandidateGiveFeedback"]],
adminCreateOpenQuestion?: [{	content: string | Variable<any, string>},ValueTypes["CreateOpenQuestion"]],
adminUpdateOpenQuestion?: [{	content: string | Variable<any, string>,	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["UpdateOpenQuestion"]],
adminDeleteOpenQuestion?: [{	id: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["DeleteOpenQuestion"]],
recruiterStarSolution?: [{	starSolution: ValueTypes["StarSolution"] | Variable<any, string>},ValueTypes["RecruiterStarSolution"]],
recruiterSeeSolution?: [{	seeSolution: ValueTypes["SeeSolution"] | Variable<any, string>},ValueTypes["RecruiterSeeSolution"]],
recruiterArchiveTest?: [{	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["RecruiterArchiveTest"]],
recruiterDeleteTest?: [{	testId: ValueTypes["UUID"] | Variable<any, string>},ValueTypes["RecruiterDeleteTest"]],
recruiterSignUp?: [{	signUp: ValueTypes["SignUpInput"] | Variable<any, string>},ValueTypes["SignUp"]],
sendResetPassword?: [{	resetPassword: ValueTypes["SendResetPasswordInput"] | Variable<any, string>},ValueTypes["SendResetPassword"]],
wizardCreateTest?: [{	input: ValueTypes["WizardCreateTestInput"] | Variable<any, string>},ValueTypes["RecruiterWizardCreateTest"]],
	recruiter?:ValueTypes["RecruiterMutation"],
	candidate?:ValueTypes["CandidateMutation"],
		__typename?: boolean | `@${string}`
}>;
	["HeroTracking"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackInput"]: {
	eventName: string | Variable<any, string>,
	properties?: ValueTypes["JSONString"] | undefined | null | Variable<any, string>
};
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]:unknown;
	["TestMutation"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Auth"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ResetPassword"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateTest"]: AliasType<{
	result?:ValueTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateTest"]: AliasType<{
	result?:ValueTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["PublishTest"]: AliasType<{
	result?:ValueTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["DisableTest"]: AliasType<{
	result?:ValueTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["EnableTest"]: AliasType<{
	result?:ValueTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteTest"]: AliasType<{
	result?:ValueTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["DuplicateTest"]: AliasType<{
	result?:ValueTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["CreateUser"]: AliasType<{
	resetToken?:boolean | `@${string}`,
	user?:ValueTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateUser"]: AliasType<{
	user?:ValueTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteUser"]: AliasType<{
	result?:ValueTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["ToggleBlockUser"]: AliasType<{
	result?:ValueTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["TriggerPasswordReset"]: AliasType<{
	resetToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImpersonateUser"]: AliasType<{
	/** Authorization token of the user with impersonation metadata */
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Issue2FAToken"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Get2FAToken"]: AliasType<{
	intermediateToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Verify2FAToken"]: AliasType<{
	intermediateToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterCandidate"]: AliasType<{
	/** Candidate's session token. */
	candidateSessionToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateAttemptSolution"]: AliasType<{
	/** Id of the typeform form for the test (typeform id). */
	typeformTestId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateGiveFeedback"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateOpenQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateOpenQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteOpenQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecruiterStarSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StarSolution"]: {
	solutionId: ValueTypes["UUID"] | Variable<any, string>,
	starred: boolean | Variable<any, string>
};
	["RecruiterSeeSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeSolution"]: {
	solutionId: ValueTypes["UUID"] | Variable<any, string>
};
	["RecruiterArchiveTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecruiterDeleteTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignUp"]: AliasType<{
	payload?:ValueTypes["RecruiterSignUpPayload"],
		__typename?: boolean | `@${string}`
}>;
	["RecruiterSignUpPayload"]: AliasType<{
	status?:boolean | `@${string}`,
	user?:ValueTypes["SignUpUser"],
	errors?:ValueTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["SignUpUser"]: AliasType<{
	email?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Error"]: AliasType<{
	name?:boolean | `@${string}`,
	messages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignUpInput"]: {
	email: string | Variable<any, string>,
	firstName: string | Variable<any, string>,
	lastName: string | Variable<any, string>,
	locale: ValueTypes["Locale"] | Variable<any, string>,
	role: ValueTypes["RecruiterRole"] | Variable<any, string>,
	phone?: string | undefined | null | Variable<any, string>
};
	["SendResetPassword"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendResetPasswordInput"]: {
	email: string | Variable<any, string>
};
	["RecruiterWizardCreateTest"]: AliasType<{
	test?:ValueTypes["WizardTest"],
		__typename?: boolean | `@${string}`
}>;
	["WizardTest"]: AliasType<{
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WizardCreateTestInput"]: {
	roleName: string | Variable<any, string>,
	competencies: Array<ValueTypes["WizardCompetencyInput"] | undefined | null> | Variable<any, string>,
	existingOpenQuestions: Array<ValueTypes["ExistingOpenQuestion"] | undefined | null> | Variable<any, string>,
	newOpenQuestions: Array<ValueTypes["NewOpenQuestion"] | undefined | null> | Variable<any, string>,
	testType: ValueTypes["TestType"] | Variable<any, string>,
	testLevel: ValueTypes["TestLevel"] | Variable<any, string>,
	description?: string | undefined | null | Variable<any, string>
};
	["ExistingOpenQuestion"]: {
	id: ValueTypes["UUID"] | Variable<any, string>
};
	["NewOpenQuestion"]: {
	text: string | Variable<any, string>
};
	["RecruiterMutation"]: AliasType<{
updateCandidatesStatus?: [{	input: ValueTypes["UpdateCandidatesStatusInput"] | Variable<any, string>},ValueTypes["UpdateCandidatesStatus"]],
signUp?: [{	signUp: ValueTypes["SignUpInput"] | Variable<any, string>},ValueTypes["SignUp"]],
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatus"]: AliasType<{
	candidates?:ValueTypes["SolutionCandidate"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatusInput"]: {
	candidateIds: Array<ValueTypes["UUID"] | undefined | null> | Variable<any, string>,
	status: ValueTypes["CandidateStatusInput"] | Variable<any, string>
};
	/** An enumeration. */
["CandidateStatusInput"]:CandidateStatusInput;
	["CandidateMutation"]: AliasType<{
startSolution?: [{	input: ValueTypes["StartSolutionInput"] | Variable<any, string>},ValueTypes["StartSolution"]],
seeQuestion?: [{	input: ValueTypes["SeeQuestionInput"] | Variable<any, string>},ValueTypes["SeeQuestion"]],
submitResponse?: [{	input: ValueTypes["SubmitResponseInput"] | Variable<any, string>},ValueTypes["SubmitResponse"]],
submitSolution?: [{	input: ValueTypes["SubmitSolutionInput"] | Variable<any, string>},ValueTypes["SubmitSolution"]],
		__typename?: boolean | `@${string}`
}>;
	["StartSolution"]: AliasType<{
	solutionAttempt?:ValueTypes["SolutionAttempt"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionAttempt"]: AliasType<{
	solutionId?:boolean | `@${string}`,
	questions?:ValueTypes["TestQuestion"],
	availableTime?:boolean | `@${string}`,
	suggestedTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestQuestion"]: AliasType<{		["...on SingleChoiceQuestion"] : ValueTypes["SingleChoiceQuestion"],
		["...on MultipleChoiceQuestion"] : ValueTypes["MultipleChoiceQuestion"],
		["...on NumericQuestion"] : ValueTypes["NumericQuestion"],
		["...on TextQuestion"] : ValueTypes["TextQuestion"]
		__typename?: boolean | `@${string}`
}>;
	["SingleChoiceQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	possibleAnswers?:ValueTypes["QuestionAnswer"],
		__typename?: boolean | `@${string}`
}>;
	["BaseTestQuestion"]:AliasType<{
		uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`;
		['...on SingleChoiceQuestion']?: Omit<ValueTypes["SingleChoiceQuestion"],keyof ValueTypes["BaseTestQuestion"]>;
		['...on MultipleChoiceQuestion']?: Omit<ValueTypes["MultipleChoiceQuestion"],keyof ValueTypes["BaseTestQuestion"]>;
		['...on NumericQuestion']?: Omit<ValueTypes["NumericQuestion"],keyof ValueTypes["BaseTestQuestion"]>;
		['...on TextQuestion']?: Omit<ValueTypes["TextQuestion"],keyof ValueTypes["BaseTestQuestion"]>;
		__typename?: boolean | `@${string}`
}>;
	["BaseTestQuestionWithAnswers"]:AliasType<{
		possibleAnswers?:ValueTypes["QuestionAnswer"];
		['...on SingleChoiceQuestion']?: Omit<ValueTypes["SingleChoiceQuestion"],keyof ValueTypes["BaseTestQuestionWithAnswers"]>;
		['...on MultipleChoiceQuestion']?: Omit<ValueTypes["MultipleChoiceQuestion"],keyof ValueTypes["BaseTestQuestionWithAnswers"]>;
		__typename?: boolean | `@${string}`
}>;
	["QuestionAnswer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MultipleChoiceQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	possibleAnswers?:ValueTypes["QuestionAnswer"],
		__typename?: boolean | `@${string}`
}>;
	["NumericQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartSolutionInput"]: {
	candidateSessionToken: string | Variable<any, string>
};
	["SeeQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeQuestionInput"]: {
	questionId: ValueTypes["UUID"] | Variable<any, string>,
	solutionId: ValueTypes["UUID"] | Variable<any, string>
};
	["SubmitResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitResponseInput"]: {
	questionId: ValueTypes["UUID"] | Variable<any, string>,
	solutionId: ValueTypes["UUID"] | Variable<any, string>,
	answersGiven: Array<ValueTypes["AnswerGivenInput"]> | Variable<any, string>
};
	["AnswerGivenInput"]: {
	text?: string | undefined | null | Variable<any, string>,
	answerId?: ValueTypes["UUID"] | undefined | null | Variable<any, string>
};
	["SubmitSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitSolutionInput"]: {
	solutionId: ValueTypes["UUID"] | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["RootQuery"]: AliasType<{
adminTestConnection?: [{	search?: string | undefined | null,	orderBy?: Array<string | undefined | null> | undefined | null,	descending?: boolean | undefined | null,	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["AdminTestConnection"]],
adminQuestionConnection?: [{	search?: string | undefined | null,	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["AdminQuestionConnection"]],
adminUserConnection?: [{	search?: string | undefined | null,	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["AdminUserConnection"]],
adminSolutionConnection?: [{	search?: string | undefined | null,	ids?: Array<ResolverInputTypes["UUID"] | undefined | null> | undefined | null,	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["AdminSolutionConnection"]],
adminTest?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["AdminTest"]],
adminUser?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["AdminBaseUser"]],
adminQuestion?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["Question"]],
	hello?:boolean | `@${string}`,
error?: [{	culprit?: string | undefined | null},boolean | `@${string}`],
competencyConnection?: [{	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["CompetencyConnection"]],
testConnection?: [{	search?: string | undefined | null,	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["TestConnection"]],
solutionConnection?: [{	search?: string | undefined | null,	ids?: Array<ResolverInputTypes["UUID"] | undefined | null> | undefined | null,	orderScoreByCompetency?: ResolverInputTypes["UUID"] | undefined | null,	orderBy?: Array<string | undefined | null> | undefined | null,	descending?: boolean | undefined | null,	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["SolutionConnection"]],
testSolutionConnection?: [{	testId?: ResolverInputTypes["UUID"] | undefined | null,	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["SolutionConnection"]],
solution?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["Solution"]],
test?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["Test"]],
candidate?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["Candidate"]],
candidateTestPreview?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["TestPreviewResult"]],
candidateSolutionPreview?: [{	/** Candidate's session token. */
	sessionToken: string},ResolverInputTypes["CandidateSolutionPreview"]],
	me?:ResolverInputTypes["BaseUser"],
candidatePreview?: [{	/** Candidate's session token. */
	sessionToken: string},ResolverInputTypes["CandidatePreview"]],
suggestedCompetencies?: [{	position?: ResolverInputTypes["PositionInput"] | undefined | null},ResolverInputTypes["SuggestedCompetencies"]],
supportedCompetencies?: [{	position: ResolverInputTypes["PositionInput"],	offset?: number | undefined | null,	before?: string | undefined | null,	after?: string | undefined | null,	first?: number | undefined | null,	last?: number | undefined | null},ResolverInputTypes["CompetencyConnection"]],
suggestedOpenQuestions?: [{	input?: Array<ResolverInputTypes["WizardCompetencyInput"] | undefined | null> | undefined | null},ResolverInputTypes["SuggestedOpenQuestions"]],
		__typename?: boolean | `@${string}`
}>;
	["AdminTestConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["AdminTestEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: AliasType<{
	/** When paginating forwards, are there more items? */
	hasNextPage?:boolean | `@${string}`,
	/** When paginating backwards, are there more items? */
	hasPreviousPage?:boolean | `@${string}`,
	/** When paginating backwards, the cursor to continue. */
	startCursor?:boolean | `@${string}`,
	/** When paginating forwards, the cursor to continue. */
	endCursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminTest` and its cursor. */
["AdminTestEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["AdminBaseTest"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminBaseTest"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	assignedTo?:ResolverInputTypes["AdminBaseUser"],
	solutionsDistribution?:ResolverInputTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questionsNo?:boolean | `@${string}`,
	totalTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]:unknown;
	["Competency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Icon"]:Icon;
	/** An enumeration. */
["Locale"]:Locale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]:unknown;
	/** An enumeration. */
["Seniority"]:Seniority;
	/** An enumeration. */
["TestState"]:TestState;
	/** An enumeration. */
["TestType"]:TestType;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]:unknown;
	["AdminBaseUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	isAdmin?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	logUrl?:boolean | `@${string}`,
	accountLogUrl?:boolean | `@${string}`,
	blocked?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["RecruiterRole"]:RecruiterRole;
	["Bin"]: AliasType<{
	label?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminQuestionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["AdminQuestionEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminQuestion` and its cursor. */
["AdminQuestionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["Question"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Question"]: AliasType<{
	uuid?:boolean | `@${string}`,
	competency?:ResolverInputTypes["Competency"],
	custom?:boolean | `@${string}`,
	questionType?:boolean | `@${string}`,
	answers?:ResolverInputTypes["Answer"],
	content?:boolean | `@${string}`,
	appendix?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	difficulty?:boolean | `@${string}`,
	questionStatus?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["QuestionType"]:QuestionType;
	["Answer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	correct?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["Difficulty"]:Difficulty;
	/** An enumeration. */
["QuestionStatus"]:QuestionStatus;
	["AdminUserConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["AdminUserEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminUser` and its cursor. */
["AdminUserEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["BaseUser"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseUser"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	isAdmin?:boolean | `@${string}`,
	locale?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	logUrl?:boolean | `@${string}`,
	accountLogUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminSolutionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["AdminSolutionEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `AdminSolution` and its cursor. */
["AdminSolutionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["Solution"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Solution"]: AliasType<{
	candidate?:ResolverInputTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ResolverInputTypes["SummaryEntry"],
	test?:ResolverInputTypes["BaseTest"],
	responses?:ResolverInputTypes["Response"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionCandidate"]: AliasType<{
	uuid?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** An enumeration. */
["CandidateStatus"]:CandidateStatus;
	/** An enumeration. */
["SolutionState"]:SolutionState;
	["SummaryEntry"]: AliasType<{
	competency?:ResolverInputTypes["SummaryCompetency"],
	numberOfQuestions?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SummaryCompetency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseTest"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	/** Owner of the test (HR user / organization) */
	assignedTo?:ResolverInputTypes["BaseUser"],
	solutionsDistribution?:ResolverInputTypes["Bin"],
	experimental?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Response"]: AliasType<{
	uuid?:boolean | `@${string}`,
	index?:boolean | `@${string}`,
	question?:ResolverInputTypes["Question"],
	answersGiven?:ResolverInputTypes["AnswerGiven"],
	score?:boolean | `@${string}`,
	outdated?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AnswerGiven"]: AliasType<{
	uuid?:boolean | `@${string}`,
	answer?:ResolverInputTypes["Answer"],
	correct?:boolean | `@${string}`,
	userSelected?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["AdminTest"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	assignedTo?:ResolverInputTypes["AdminBaseUser"],
	solutionsDistribution?:ResolverInputTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questionsNo?:boolean | `@${string}`,
	totalTime?:boolean | `@${string}`,
	questions?:ResolverInputTypes["Question"],
	averageSummary?:ResolverInputTypes["SummaryEntry"],
	solutionUuids?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompetencyConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["CompetencyEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Competency` and its cursor. */
["CompetencyEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["Competency"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["TestEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Test` and its cursor. */
["TestEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["BaseTest"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SolutionConnection"]: AliasType<{
	/** Pagination data for this connection. */
	pageInfo?:ResolverInputTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges?:ResolverInputTypes["SolutionEdge"],
	/** Total count of available results */
	totalCount?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: AliasType<{
	/** The item at the end of the edge */
	node?:ResolverInputTypes["BaseSolution"],
	/** A cursor for use in pagination */
	cursor?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseSolution"]: AliasType<{
	candidate?:ResolverInputTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ResolverInputTypes["SummaryEntry"],
		__typename?: boolean | `@${string}`
}>;
	["Test"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Competency"],
	locale?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	mostRecentActivityAt?:boolean | `@${string}`,
	mostRecentActivitySecondsAgo?:boolean | `@${string}`,
	disabledAt?:boolean | `@${string}`,
	typeformTestId?:boolean | `@${string}`,
	seniority?:boolean | `@${string}`,
	testState?:boolean | `@${string}`,
	testType?:boolean | `@${string}`,
	/** Number of submitted solutions to the test */
	completedBy?:boolean | `@${string}`,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?:boolean | `@${string}`,
	/** Average score of solutions */
	averageSolutionScore?:boolean | `@${string}`,
	competencyNames?:boolean | `@${string}`,
	/** Owner of the test (HR user / organization) */
	assignedTo?:ResolverInputTypes["BaseUser"],
	solutionsDistribution?:ResolverInputTypes["Bin"],
	experimental?:boolean | `@${string}`,
	questions?:ResolverInputTypes["Question"],
	averageSummary?:ResolverInputTypes["SummaryEntry"],
	solutionUuids?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Candidate"]: AliasType<{
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	disabled?:boolean | `@${string}`,
	assessments?:ResolverInputTypes["Assessment"],
	solutions?:ResolverInputTypes["BaseSolution"],
		__typename?: boolean | `@${string}`
}>;
	["Assessment"]: AliasType<{
	candidate?:ResolverInputTypes["SolutionCandidate"],
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	seenAt?:boolean | `@${string}`,
	submittedAt?:boolean | `@${string}`,
	testRoleName?:boolean | `@${string}`,
	starred?:boolean | `@${string}`,
	solutionState?:boolean | `@${string}`,
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ResolverInputTypes["SummaryEntry"],
	test?:ResolverInputTypes["BaseTest"],
	responses?:ResolverInputTypes["Response"],
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewResult"]: AliasType<{
	TestPreview?:ResolverInputTypes["TestPreview"],
	TestPreviewUnavailable?:ResolverInputTypes["TestPreviewUnavailable"],
		__typename?: boolean | `@${string}`
}>;
	["TestPreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	roleName?:boolean | `@${string}`,
	availableTime?:boolean | `@${string}`,
	numberOfQuestions?:boolean | `@${string}`,
	organizationName?:boolean | `@${string}`,
	requiresExternalTool?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	competencies?:ResolverInputTypes["Competency"],
	locale?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestPreviewUnavailable"]: AliasType<{
	reason?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateSolutionPreview"]: AliasType<{
	test?:ResolverInputTypes["TestPreview"],
	solutionState?:boolean | `@${string}`,
	candidate?:ResolverInputTypes["CandidatePreview"],
	score?:boolean | `@${string}`,
	timeSpent?:boolean | `@${string}`,
	summary?:ResolverInputTypes["SummaryEntry"],
		__typename?: boolean | `@${string}`
}>;
	["CandidatePreview"]: AliasType<{
	uuid?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuggestedCompetencies"]: AliasType<{
	selected?:ResolverInputTypes["SuggestedCompetency"],
		__typename?: boolean | `@${string}`
}>;
	["SuggestedCompetency"]: AliasType<{
	uuid?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	domain?:boolean | `@${string}`,
	icon?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PositionInput"]: {
	jobTitle: string,
	seniority?: ResolverInputTypes["Seniority"] | undefined | null,
	testLevel: ResolverInputTypes["TestLevel"],
	testType: ResolverInputTypes["TestType"],
	description?: string | undefined | null
};
	/** An enumeration. */
["TestLevel"]:TestLevel;
	["SuggestedOpenQuestions"]: AliasType<{
	suggested?:ResolverInputTypes["SuggestedOpenQuestion"],
		__typename?: boolean | `@${string}`
}>;
	["SuggestedOpenQuestion"]: AliasType<{
	competencyName?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WizardCompetencyInput"]: {
	id: ResolverInputTypes["UUID"]
};
	["MutationsRoot"]: AliasType<{
hero?: [{	input: ResolverInputTypes["TrackInput"]},ResolverInputTypes["HeroTracking"]],
test?: [{	name: string},ResolverInputTypes["TestMutation"]],
auth?: [{	email: string,	password: string},ResolverInputTypes["Auth"]],
resetPassword?: [{	password: string,	resetToken: string},ResolverInputTypes["ResetPassword"]],
adminCreateTest?: [{	availableTime: number,	description: string,	experimental?: boolean | undefined | null,	questions: Array<ResolverInputTypes["UUID"] | undefined | null>,	roleName: string,	seniority: ResolverInputTypes["Seniority"],	testType: ResolverInputTypes["TestType"],	title: string,	user?: ResolverInputTypes["UUID"] | undefined | null},ResolverInputTypes["CreateTest"]],
adminUpdateTest?: [{	availableTime?: number | undefined | null,	description?: string | undefined | null,	experimental?: boolean | undefined | null,	questions?: Array<ResolverInputTypes["UUID"] | undefined | null> | undefined | null,	roleName?: string | undefined | null,	seniority?: ResolverInputTypes["Seniority"] | undefined | null,	testType?: ResolverInputTypes["TestType"] | undefined | null,	testUuid: ResolverInputTypes["UUID"],	title?: string | undefined | null},ResolverInputTypes["UpdateTest"]],
adminPublishTest?: [{	testUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["PublishTest"]],
adminDisableTest?: [{	testUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["DisableTest"]],
adminEnableTest?: [{	testUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["EnableTest"]],
adminDeleteTest?: [{	testUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["DeleteTest"]],
adminDuplicateTest?: [{	newUser?: ResolverInputTypes["UUID"] | undefined | null,	testUuid: ResolverInputTypes["UUID"]},ResolverInputTypes["DuplicateTest"]],
adminCreateUser?: [{	email: string,	firstName: string,	lastName: string,	locale: ResolverInputTypes["Locale"],	organizationName?: string | undefined | null},ResolverInputTypes["CreateUser"]],
adminUpdateUser?: [{	email: string,	firstName: string,	id: ResolverInputTypes["UUID"],	lastName: string,	locale: ResolverInputTypes["Locale"],	organizationName?: string | undefined | null},ResolverInputTypes["UpdateUser"]],
adminDeleteUser?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["DeleteUser"]],
adminToggleBlockUser?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["ToggleBlockUser"]],
adminTriggerPasswordReset?: [{	uuid: ResolverInputTypes["UUID"]},ResolverInputTypes["TriggerPasswordReset"]],
adminImpersonateUser?: [{	/** Uuid of the user to impersonate */
	userId: ResolverInputTypes["UUID"]},ResolverInputTypes["ImpersonateUser"]],
candidateIssue2faToken?: [{	/** Phone number to verify by SMS code. */
	phoneNumber: string,	/** Public id of the test being attempted. */
	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["Issue2FAToken"]],
candidateGet2faToken?: [{	/** Phone number to which the verification token was sent. */
	phoneNumber: string,	/** Public id of the test being attempted. */
	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["Get2FAToken"]],
candidateVerify2faToken?: [{	/** Phone number to which the verification token was sent. */
	phoneNumber: string,	/** Public id of the test being attempted. */
	testId: ResolverInputTypes["UUID"],	/** Verification token sent to candidate via SMS. */
	verificationToken: string},ResolverInputTypes["Verify2FAToken"]],
registerCandidate?: [{	/** Candidate's email. */
	email: string,	/** Candidate's first name. */
	firstName: string,	/** Intermediate token received after successful phone number verification. */
	intermediateToken: string,	/** Candidate's last name. */
	lastName: string},ResolverInputTypes["RegisterCandidate"]],
candidateAttemptSolution?: [{	/** Candidate's session token. */
	candidateSessionToken: string},ResolverInputTypes["CandidateAttemptSolution"]],
candidateGiveFeedback?: [{	/** Candidate's session token. */
	candidateSessionToken: string,	/** Feedback score given by a candidate. */
	feedbackScore?: ResolverInputTypes["Decimal"] | undefined | null,	/** Feedback score type given by a candidate. */
	feedbackScoreVersion?: string | undefined | null,	/** Text feedback given by a candidate. */
	feedbackText?: string | undefined | null},ResolverInputTypes["CandidateGiveFeedback"]],
adminCreateOpenQuestion?: [{	content: string},ResolverInputTypes["CreateOpenQuestion"]],
adminUpdateOpenQuestion?: [{	content: string,	id: ResolverInputTypes["UUID"]},ResolverInputTypes["UpdateOpenQuestion"]],
adminDeleteOpenQuestion?: [{	id: ResolverInputTypes["UUID"]},ResolverInputTypes["DeleteOpenQuestion"]],
recruiterStarSolution?: [{	starSolution: ResolverInputTypes["StarSolution"]},ResolverInputTypes["RecruiterStarSolution"]],
recruiterSeeSolution?: [{	seeSolution: ResolverInputTypes["SeeSolution"]},ResolverInputTypes["RecruiterSeeSolution"]],
recruiterArchiveTest?: [{	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["RecruiterArchiveTest"]],
recruiterDeleteTest?: [{	testId: ResolverInputTypes["UUID"]},ResolverInputTypes["RecruiterDeleteTest"]],
recruiterSignUp?: [{	signUp: ResolverInputTypes["SignUpInput"]},ResolverInputTypes["SignUp"]],
sendResetPassword?: [{	resetPassword: ResolverInputTypes["SendResetPasswordInput"]},ResolverInputTypes["SendResetPassword"]],
wizardCreateTest?: [{	input: ResolverInputTypes["WizardCreateTestInput"]},ResolverInputTypes["RecruiterWizardCreateTest"]],
	recruiter?:ResolverInputTypes["RecruiterMutation"],
	candidate?:ResolverInputTypes["CandidateMutation"],
		__typename?: boolean | `@${string}`
}>;
	["HeroTracking"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TrackInput"]: {
	eventName: string,
	properties?: ResolverInputTypes["JSONString"] | undefined | null
};
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]:unknown;
	["TestMutation"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Auth"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ResetPassword"]: AliasType<{
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateTest"]: AliasType<{
	result?:ResolverInputTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateTest"]: AliasType<{
	result?:ResolverInputTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["PublishTest"]: AliasType<{
	result?:ResolverInputTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["DisableTest"]: AliasType<{
	result?:ResolverInputTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["EnableTest"]: AliasType<{
	result?:ResolverInputTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteTest"]: AliasType<{
	result?:ResolverInputTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["DuplicateTest"]: AliasType<{
	result?:ResolverInputTypes["AdminTest"],
		__typename?: boolean | `@${string}`
}>;
	["CreateUser"]: AliasType<{
	resetToken?:boolean | `@${string}`,
	user?:ResolverInputTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateUser"]: AliasType<{
	user?:ResolverInputTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["DeleteUser"]: AliasType<{
	result?:ResolverInputTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["ToggleBlockUser"]: AliasType<{
	result?:ResolverInputTypes["AdminBaseUser"],
		__typename?: boolean | `@${string}`
}>;
	["TriggerPasswordReset"]: AliasType<{
	resetToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ImpersonateUser"]: AliasType<{
	/** Authorization token of the user with impersonation metadata */
	authorizationToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Issue2FAToken"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Get2FAToken"]: AliasType<{
	intermediateToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Verify2FAToken"]: AliasType<{
	intermediateToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RegisterCandidate"]: AliasType<{
	/** Candidate's session token. */
	candidateSessionToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateAttemptSolution"]: AliasType<{
	/** Id of the typeform form for the test (typeform id). */
	typeformTestId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CandidateGiveFeedback"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CreateOpenQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UpdateOpenQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	content?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeleteOpenQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecruiterStarSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StarSolution"]: {
	solutionId: ResolverInputTypes["UUID"],
	starred: boolean
};
	["RecruiterSeeSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeSolution"]: {
	solutionId: ResolverInputTypes["UUID"]
};
	["RecruiterArchiveTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["RecruiterDeleteTest"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignUp"]: AliasType<{
	payload?:ResolverInputTypes["RecruiterSignUpPayload"],
		__typename?: boolean | `@${string}`
}>;
	["RecruiterSignUpPayload"]: AliasType<{
	status?:boolean | `@${string}`,
	user?:ResolverInputTypes["SignUpUser"],
	errors?:ResolverInputTypes["Error"],
		__typename?: boolean | `@${string}`
}>;
	["SignUpUser"]: AliasType<{
	email?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	accountId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Error"]: AliasType<{
	name?:boolean | `@${string}`,
	messages?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignUpInput"]: {
	email: string,
	firstName: string,
	lastName: string,
	locale: ResolverInputTypes["Locale"],
	role: ResolverInputTypes["RecruiterRole"],
	phone?: string | undefined | null
};
	["SendResetPassword"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SendResetPasswordInput"]: {
	email: string
};
	["RecruiterWizardCreateTest"]: AliasType<{
	test?:ResolverInputTypes["WizardTest"],
		__typename?: boolean | `@${string}`
}>;
	["WizardTest"]: AliasType<{
	id?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WizardCreateTestInput"]: {
	roleName: string,
	competencies: Array<ResolverInputTypes["WizardCompetencyInput"] | undefined | null>,
	existingOpenQuestions: Array<ResolverInputTypes["ExistingOpenQuestion"] | undefined | null>,
	newOpenQuestions: Array<ResolverInputTypes["NewOpenQuestion"] | undefined | null>,
	testType: ResolverInputTypes["TestType"],
	testLevel: ResolverInputTypes["TestLevel"],
	description?: string | undefined | null
};
	["ExistingOpenQuestion"]: {
	id: ResolverInputTypes["UUID"]
};
	["NewOpenQuestion"]: {
	text: string
};
	["RecruiterMutation"]: AliasType<{
updateCandidatesStatus?: [{	input: ResolverInputTypes["UpdateCandidatesStatusInput"]},ResolverInputTypes["UpdateCandidatesStatus"]],
signUp?: [{	signUp: ResolverInputTypes["SignUpInput"]},ResolverInputTypes["SignUp"]],
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatus"]: AliasType<{
	candidates?:ResolverInputTypes["SolutionCandidate"],
		__typename?: boolean | `@${string}`
}>;
	["UpdateCandidatesStatusInput"]: {
	candidateIds: Array<ResolverInputTypes["UUID"] | undefined | null>,
	status: ResolverInputTypes["CandidateStatusInput"]
};
	/** An enumeration. */
["CandidateStatusInput"]:CandidateStatusInput;
	["CandidateMutation"]: AliasType<{
startSolution?: [{	input: ResolverInputTypes["StartSolutionInput"]},ResolverInputTypes["StartSolution"]],
seeQuestion?: [{	input: ResolverInputTypes["SeeQuestionInput"]},ResolverInputTypes["SeeQuestion"]],
submitResponse?: [{	input: ResolverInputTypes["SubmitResponseInput"]},ResolverInputTypes["SubmitResponse"]],
submitSolution?: [{	input: ResolverInputTypes["SubmitSolutionInput"]},ResolverInputTypes["SubmitSolution"]],
		__typename?: boolean | `@${string}`
}>;
	["StartSolution"]: AliasType<{
	solutionAttempt?:ResolverInputTypes["SolutionAttempt"],
		__typename?: boolean | `@${string}`
}>;
	["SolutionAttempt"]: AliasType<{
	solutionId?:boolean | `@${string}`,
	questions?:ResolverInputTypes["TestQuestion"],
	availableTime?:boolean | `@${string}`,
	suggestedTime?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TestQuestion"]: AliasType<{
	SingleChoiceQuestion?:ResolverInputTypes["SingleChoiceQuestion"],
	MultipleChoiceQuestion?:ResolverInputTypes["MultipleChoiceQuestion"],
	NumericQuestion?:ResolverInputTypes["NumericQuestion"],
	TextQuestion?:ResolverInputTypes["TextQuestion"],
		__typename?: boolean | `@${string}`
}>;
	["SingleChoiceQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	possibleAnswers?:ResolverInputTypes["QuestionAnswer"],
		__typename?: boolean | `@${string}`
}>;
	["BaseTestQuestion"]:AliasType<{
		uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`;
		['...on SingleChoiceQuestion']?: Omit<ResolverInputTypes["SingleChoiceQuestion"],keyof ResolverInputTypes["BaseTestQuestion"]>;
		['...on MultipleChoiceQuestion']?: Omit<ResolverInputTypes["MultipleChoiceQuestion"],keyof ResolverInputTypes["BaseTestQuestion"]>;
		['...on NumericQuestion']?: Omit<ResolverInputTypes["NumericQuestion"],keyof ResolverInputTypes["BaseTestQuestion"]>;
		['...on TextQuestion']?: Omit<ResolverInputTypes["TextQuestion"],keyof ResolverInputTypes["BaseTestQuestion"]>;
		__typename?: boolean | `@${string}`
}>;
	["BaseTestQuestionWithAnswers"]:AliasType<{
		possibleAnswers?:ResolverInputTypes["QuestionAnswer"];
		['...on SingleChoiceQuestion']?: Omit<ResolverInputTypes["SingleChoiceQuestion"],keyof ResolverInputTypes["BaseTestQuestionWithAnswers"]>;
		['...on MultipleChoiceQuestion']?: Omit<ResolverInputTypes["MultipleChoiceQuestion"],keyof ResolverInputTypes["BaseTestQuestionWithAnswers"]>;
		__typename?: boolean | `@${string}`
}>;
	["QuestionAnswer"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["MultipleChoiceQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
	possibleAnswers?:ResolverInputTypes["QuestionAnswer"],
		__typename?: boolean | `@${string}`
}>;
	["NumericQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["TextQuestion"]: AliasType<{
	uuid?:boolean | `@${string}`,
	text?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["StartSolutionInput"]: {
	candidateSessionToken: string
};
	["SeeQuestion"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SeeQuestionInput"]: {
	questionId: ResolverInputTypes["UUID"],
	solutionId: ResolverInputTypes["UUID"]
};
	["SubmitResponse"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitResponseInput"]: {
	questionId: ResolverInputTypes["UUID"],
	solutionId: ResolverInputTypes["UUID"],
	answersGiven: Array<ResolverInputTypes["AnswerGivenInput"]>
};
	["AnswerGivenInput"]: {
	text?: string | undefined | null,
	answerId?: ResolverInputTypes["UUID"] | undefined | null
};
	["SubmitSolution"]: AliasType<{
	result?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SubmitSolutionInput"]: {
	solutionId: ResolverInputTypes["UUID"]
}
  }

export type ModelTypes = {
    ["RootQuery"]: {
		adminTestConnection?: ModelTypes["AdminTestConnection"] | undefined,
	adminQuestionConnection?: ModelTypes["AdminQuestionConnection"] | undefined,
	adminUserConnection?: ModelTypes["AdminUserConnection"] | undefined,
	adminSolutionConnection?: ModelTypes["AdminSolutionConnection"] | undefined,
	adminTest?: ModelTypes["AdminTest"] | undefined,
	adminUser?: ModelTypes["AdminBaseUser"] | undefined,
	adminQuestion?: ModelTypes["Question"] | undefined,
	hello?: string | undefined,
	error?: string | undefined,
	competencyConnection?: ModelTypes["CompetencyConnection"] | undefined,
	testConnection?: ModelTypes["TestConnection"] | undefined,
	solutionConnection?: ModelTypes["SolutionConnection"] | undefined,
	testSolutionConnection?: ModelTypes["SolutionConnection"] | undefined,
	solution?: ModelTypes["Solution"] | undefined,
	test?: ModelTypes["Test"] | undefined,
	candidate?: ModelTypes["Candidate"] | undefined,
	/** Preview Test details with just a test uuid as a candidate. */
	candidateTestPreview?: ModelTypes["TestPreviewResult"] | undefined,
	/** Preview Solution details with a session token as a candidate. */
	candidateSolutionPreview?: ModelTypes["CandidateSolutionPreview"] | undefined,
	me?: ModelTypes["BaseUser"] | undefined,
	/** Preview Candidate details with a candidate session token. */
	candidatePreview?: ModelTypes["CandidatePreview"] | undefined,
	suggestedCompetencies: ModelTypes["SuggestedCompetencies"],
	supportedCompetencies?: ModelTypes["CompetencyConnection"] | undefined,
	suggestedOpenQuestions: ModelTypes["SuggestedOpenQuestions"]
};
	["AdminTestConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["AdminTestEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: {
		/** When paginating forwards, are there more items? */
	hasNextPage: boolean,
	/** When paginating backwards, are there more items? */
	hasPreviousPage: boolean,
	/** When paginating backwards, the cursor to continue. */
	startCursor?: string | undefined,
	/** When paginating forwards, the cursor to continue. */
	endCursor?: string | undefined
};
	/** A Relay edge containing a `AdminTest` and its cursor. */
["AdminTestEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["AdminBaseTest"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["AdminBaseTest"]: {
		uuid: ModelTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<ModelTypes["Competency"] | undefined> | undefined,
	locale: ModelTypes["Locale"],
	createdAt: ModelTypes["DateTime"],
	updatedAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivityAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: ModelTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: ModelTypes["Seniority"],
	testState: ModelTypes["TestState"],
	testType: ModelTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: ModelTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	assignedTo?: ModelTypes["AdminBaseUser"] | undefined,
	solutionsDistribution: Array<ModelTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questionsNo: number,
	totalTime: number
};
	/** Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]:any;
	["Competency"]: {
		uuid: ModelTypes["UUID"],
	name: string,
	domain: string,
	icon: ModelTypes["Icon"],
	description: string
};
	["Icon"]:Icon;
	["Locale"]:Locale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]:any;
	["Seniority"]:Seniority;
	["TestState"]:TestState;
	["TestType"]:TestType;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]:any;
	["AdminBaseUser"]: {
		uuid: ModelTypes["UUID"],
	firstName: string,
	lastName: string,
	organizationName?: string | undefined,
	email: string,
	isAdmin: boolean,
	locale: ModelTypes["Locale"],
	accountId: number,
	role?: ModelTypes["RecruiterRole"] | undefined,
	logUrl: string,
	accountLogUrl: string,
	blocked: boolean
};
	["RecruiterRole"]:RecruiterRole;
	["Bin"]: {
		label: string,
	value: number
};
	["AdminQuestionConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["AdminQuestionEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `AdminQuestion` and its cursor. */
["AdminQuestionEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["Question"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["Question"]: {
		uuid: ModelTypes["UUID"],
	competency?: ModelTypes["Competency"] | undefined,
	custom: boolean,
	questionType: ModelTypes["QuestionType"],
	answers?: Array<ModelTypes["Answer"] | undefined> | undefined,
	content: string,
	appendix?: string | undefined,
	availableTime: number,
	difficulty: ModelTypes["Difficulty"],
	questionStatus: ModelTypes["QuestionStatus"],
	requiresExternalTool?: string | undefined
};
	["QuestionType"]:QuestionType;
	["Answer"]: {
		uuid: ModelTypes["UUID"],
	correct: boolean,
	content: string
};
	["Difficulty"]:Difficulty;
	["QuestionStatus"]:QuestionStatus;
	["AdminUserConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["AdminUserEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `AdminUser` and its cursor. */
["AdminUserEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["BaseUser"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["BaseUser"]: {
		uuid: ModelTypes["UUID"],
	firstName: string,
	lastName: string,
	organizationName?: string | undefined,
	email: string,
	isAdmin: boolean,
	locale: ModelTypes["Locale"],
	accountId: number,
	role?: ModelTypes["RecruiterRole"] | undefined,
	logUrl: string,
	accountLogUrl: string
};
	["AdminSolutionConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["AdminSolutionEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `AdminSolution` and its cursor. */
["AdminSolutionEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["Solution"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["Solution"]: {
		candidate: ModelTypes["SolutionCandidate"],
	uuid: ModelTypes["UUID"],
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	seenAt?: ModelTypes["DateTime"] | undefined,
	submittedAt: ModelTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: ModelTypes["SolutionState"],
	score: ModelTypes["Decimal"],
	timeSpent: number,
	summary?: Array<ModelTypes["SummaryEntry"] | undefined> | undefined,
	test: ModelTypes["BaseTest"],
	responses?: Array<ModelTypes["Response"] | undefined> | undefined
};
	["SolutionCandidate"]: {
		uuid: ModelTypes["UUID"],
	firstName: string,
	lastName: string,
	email: string,
	phoneNumber: string,
	status: ModelTypes["CandidateStatus"]
};
	["CandidateStatus"]:CandidateStatus;
	["SolutionState"]:SolutionState;
	["SummaryEntry"]: {
		competency: ModelTypes["SummaryCompetency"],
	numberOfQuestions: number,
	score: ModelTypes["Decimal"]
};
	["SummaryCompetency"]: {
		uuid: ModelTypes["UUID"],
	name: string
};
	["BaseTest"]: {
		uuid: ModelTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<ModelTypes["Competency"] | undefined> | undefined,
	locale: ModelTypes["Locale"],
	createdAt: ModelTypes["DateTime"],
	updatedAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivityAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: ModelTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: ModelTypes["Seniority"],
	testState: ModelTypes["TestState"],
	testType: ModelTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: ModelTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	/** Owner of the test (HR user / organization) */
	assignedTo?: ModelTypes["BaseUser"] | undefined,
	solutionsDistribution: Array<ModelTypes["Bin"] | undefined>,
	experimental?: boolean | undefined
};
	["Response"]: {
		uuid: ModelTypes["UUID"],
	index?: number | undefined,
	question?: ModelTypes["Question"] | undefined,
	answersGiven?: Array<ModelTypes["AnswerGiven"] | undefined> | undefined,
	score?: ModelTypes["Decimal"] | undefined,
	outdated: boolean
};
	["AnswerGiven"]: {
		uuid: ModelTypes["UUID"],
	answer?: ModelTypes["Answer"] | undefined,
	correct?: boolean | undefined,
	userSelected: boolean,
	content?: string | undefined
};
	["AdminTest"]: {
		uuid: ModelTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<ModelTypes["Competency"] | undefined> | undefined,
	locale: ModelTypes["Locale"],
	createdAt: ModelTypes["DateTime"],
	updatedAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivityAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: ModelTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: ModelTypes["Seniority"],
	testState: ModelTypes["TestState"],
	testType: ModelTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: ModelTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	assignedTo?: ModelTypes["AdminBaseUser"] | undefined,
	solutionsDistribution: Array<ModelTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questionsNo: number,
	totalTime: number,
	questions?: Array<ModelTypes["Question"] | undefined> | undefined,
	averageSummary?: Array<ModelTypes["SummaryEntry"] | undefined> | undefined,
	solutionUuids?: Array<ModelTypes["UUID"] | undefined> | undefined
};
	["CompetencyConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["CompetencyEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `Competency` and its cursor. */
["CompetencyEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["Competency"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["TestConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["TestEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `Test` and its cursor. */
["TestEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["BaseTest"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SolutionConnection"]: {
		/** Pagination data for this connection. */
	pageInfo: ModelTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<ModelTypes["SolutionEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: {
		/** The item at the end of the edge */
	node?: ModelTypes["BaseSolution"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["BaseSolution"]: {
		candidate: ModelTypes["SolutionCandidate"],
	uuid: ModelTypes["UUID"],
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	seenAt?: ModelTypes["DateTime"] | undefined,
	submittedAt: ModelTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: ModelTypes["SolutionState"],
	score: ModelTypes["Decimal"],
	timeSpent: number,
	summary?: Array<ModelTypes["SummaryEntry"] | undefined> | undefined
};
	["Test"]: {
		uuid: ModelTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<ModelTypes["Competency"] | undefined> | undefined,
	locale: ModelTypes["Locale"],
	createdAt: ModelTypes["DateTime"],
	updatedAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivityAt?: ModelTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: ModelTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: ModelTypes["Seniority"],
	testState: ModelTypes["TestState"],
	testType: ModelTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: ModelTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	/** Owner of the test (HR user / organization) */
	assignedTo?: ModelTypes["BaseUser"] | undefined,
	solutionsDistribution: Array<ModelTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questions?: Array<ModelTypes["Question"] | undefined> | undefined,
	averageSummary?: Array<ModelTypes["SummaryEntry"] | undefined> | undefined,
	solutionUuids?: Array<ModelTypes["UUID"] | undefined> | undefined
};
	["Candidate"]: {
		uuid: ModelTypes["UUID"],
	phoneNumber?: string | undefined,
	firstName: string,
	lastName: string,
	email: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt?: ModelTypes["DateTime"] | undefined,
	disabled?: boolean | undefined,
	assessments?: Array<ModelTypes["Assessment"] | undefined> | undefined,
	solutions?: Array<ModelTypes["BaseSolution"] | undefined> | undefined
};
	["Assessment"]: {
		candidate: ModelTypes["SolutionCandidate"],
	uuid: ModelTypes["UUID"],
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	seenAt?: ModelTypes["DateTime"] | undefined,
	submittedAt: ModelTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: ModelTypes["SolutionState"],
	score: ModelTypes["Decimal"],
	timeSpent: number,
	summary?: Array<ModelTypes["SummaryEntry"] | undefined> | undefined,
	test: ModelTypes["BaseTest"],
	responses?: Array<ModelTypes["Response"] | undefined> | undefined
};
	["TestPreviewResult"]:ModelTypes["TestPreview"] | ModelTypes["TestPreviewUnavailable"];
	["TestPreview"]: {
		uuid: ModelTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<ModelTypes["Competency"] | undefined> | undefined,
	locale: ModelTypes["Locale"]
};
	["TestPreviewUnavailable"]: {
		reason: string
};
	["CandidateSolutionPreview"]: {
		test: ModelTypes["TestPreview"],
	solutionState: ModelTypes["SolutionState"],
	candidate: ModelTypes["CandidatePreview"],
	score?: ModelTypes["Decimal"] | undefined,
	timeSpent?: number | undefined,
	summary?: Array<ModelTypes["SummaryEntry"] | undefined> | undefined
};
	["CandidatePreview"]: {
		uuid: ModelTypes["UUID"],
	phoneNumber?: string | undefined,
	firstName: string,
	lastName: string,
	email: string
};
	["SuggestedCompetencies"]: {
		selected: Array<ModelTypes["SuggestedCompetency"] | undefined>
};
	["SuggestedCompetency"]: {
		uuid: ModelTypes["UUID"],
	name: string,
	domain: string,
	icon: ModelTypes["Icon"],
	description: string
};
	["PositionInput"]: {
	jobTitle: string,
	seniority?: ModelTypes["Seniority"] | undefined,
	testLevel: ModelTypes["TestLevel"],
	testType: ModelTypes["TestType"],
	description?: string | undefined
};
	["TestLevel"]:TestLevel;
	["SuggestedOpenQuestions"]: {
		suggested: Array<ModelTypes["SuggestedOpenQuestion"] | undefined>
};
	["SuggestedOpenQuestion"]: {
		competencyName: string,
	content: string,
	uuid: ModelTypes["UUID"]
};
	["WizardCompetencyInput"]: {
	id: ModelTypes["UUID"]
};
	["MutationsRoot"]: {
		hero?: ModelTypes["HeroTracking"] | undefined,
	/** Mutation for testing purposes */
	test?: ModelTypes["TestMutation"] | undefined,
	/** Authenticate using an email and password */
	auth?: ModelTypes["Auth"] | undefined,
	/** Reset password using a password reset token */
	resetPassword?: ModelTypes["ResetPassword"] | undefined,
	/** Manually create a test draft as an admin */
	adminCreateTest?: ModelTypes["CreateTest"] | undefined,
	/** Update a test draft as an admin */
	adminUpdateTest?: ModelTypes["UpdateTest"] | undefined,
	/** Publish a test draft as an admin */
	adminPublishTest?: ModelTypes["PublishTest"] | undefined,
	/** Disable an active test as an admin */
	adminDisableTest?: ModelTypes["DisableTest"] | undefined,
	/** Enable an inactive test as an admin */
	adminEnableTest?: ModelTypes["EnableTest"] | undefined,
	/** Delete a test as an admin */
	adminDeleteTest?: ModelTypes["DeleteTest"] | undefined,
	/** Duplicate a test in any state to a new draft */
	adminDuplicateTest?: ModelTypes["DuplicateTest"] | undefined,
	/** Create a HR user, acquiring a password reset token */
	adminCreateUser?: ModelTypes["CreateUser"] | undefined,
	/** Update a HR user */
	adminUpdateUser?: ModelTypes["UpdateUser"] | undefined,
	/** Delete a user as an admin */
	adminDeleteUser?: ModelTypes["DeleteUser"] | undefined,
	/** Toggle user block as an admin */
	adminToggleBlockUser?: ModelTypes["ToggleBlockUser"] | undefined,
	/** Acquire a password reset token for an existing user */
	adminTriggerPasswordReset?: ModelTypes["TriggerPasswordReset"] | undefined,
	/** Acquire an authorization token to impersonate an existing user */
	adminImpersonateUser?: ModelTypes["ImpersonateUser"] | undefined,
	/** Issue 2FA token for phone number verification as a candidate. */
	candidateIssue2faToken?: ModelTypes["Issue2FAToken"] | undefined,
	candidateGet2faToken?: ModelTypes["Get2FAToken"] | undefined,
	/** Verify a 2FA token and receive an intermediate token as a candidate. */
	candidateVerify2faToken?: ModelTypes["Verify2FAToken"] | undefined,
	/** Register as a candidate using an intermediate token from 2FA verification. */
	registerCandidate?: ModelTypes["RegisterCandidate"] | undefined,
	/** Attempt solution as a candidate using a session token. */
	candidateAttemptSolution?: ModelTypes["CandidateAttemptSolution"] | undefined,
	/** Give feedback as a candidate using a session token. */
	candidateGiveFeedback?: ModelTypes["CandidateGiveFeedback"] | undefined,
	/** Create an open question */
	adminCreateOpenQuestion?: ModelTypes["CreateOpenQuestion"] | undefined,
	/** Update an open question */
	adminUpdateOpenQuestion?: ModelTypes["UpdateOpenQuestion"] | undefined,
	/** Delete an open question */
	adminDeleteOpenQuestion?: ModelTypes["DeleteOpenQuestion"] | undefined,
	/** Recruiter star a solution */
	recruiterStarSolution?: ModelTypes["RecruiterStarSolution"] | undefined,
	/** Recruiter see a solution */
	recruiterSeeSolution?: ModelTypes["RecruiterSeeSolution"] | undefined,
	/** Recruiter archive a test */
	recruiterArchiveTest?: ModelTypes["RecruiterArchiveTest"] | undefined,
	/** Recruiter delete a test */
	recruiterDeleteTest?: ModelTypes["RecruiterDeleteTest"] | undefined,
	/** Recruiter Sign Up */
	recruiterSignUp?: ModelTypes["SignUp"] | undefined,
	/** Send reset password */
	sendResetPassword?: ModelTypes["SendResetPassword"] | undefined,
	/** Wizard create a test */
	wizardCreateTest?: ModelTypes["RecruiterWizardCreateTest"] | undefined,
	recruiter?: ModelTypes["RecruiterMutation"] | undefined,
	candidate?: ModelTypes["CandidateMutation"] | undefined
};
	["HeroTracking"]: {
		result: string
};
	["TrackInput"]: {
	eventName: string,
	properties?: ModelTypes["JSONString"] | undefined
};
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]:any;
	["TestMutation"]: {
		result: string
};
	["Auth"]: {
		authorizationToken: string
};
	["ResetPassword"]: {
		authorizationToken: string
};
	["CreateTest"]: {
		result?: ModelTypes["AdminTest"] | undefined
};
	["UpdateTest"]: {
		result?: ModelTypes["AdminTest"] | undefined
};
	["PublishTest"]: {
		result?: ModelTypes["AdminTest"] | undefined
};
	["DisableTest"]: {
		result?: ModelTypes["AdminTest"] | undefined
};
	["EnableTest"]: {
		result?: ModelTypes["AdminTest"] | undefined
};
	["DeleteTest"]: {
		result?: ModelTypes["AdminTest"] | undefined
};
	["DuplicateTest"]: {
		result?: ModelTypes["AdminTest"] | undefined
};
	["CreateUser"]: {
		resetToken: string,
	user: ModelTypes["AdminBaseUser"]
};
	["UpdateUser"]: {
		user: ModelTypes["AdminBaseUser"]
};
	["DeleteUser"]: {
		result?: ModelTypes["AdminBaseUser"] | undefined
};
	["ToggleBlockUser"]: {
		result?: ModelTypes["AdminBaseUser"] | undefined
};
	["TriggerPasswordReset"]: {
		resetToken: string
};
	["ImpersonateUser"]: {
		/** Authorization token of the user with impersonation metadata */
	authorizationToken: string
};
	["Issue2FAToken"]: {
		result: string
};
	["Get2FAToken"]: {
		intermediateToken: string
};
	["Verify2FAToken"]: {
		intermediateToken: string
};
	["RegisterCandidate"]: {
		/** Candidate's session token. */
	candidateSessionToken: string
};
	["CandidateAttemptSolution"]: {
		/** Id of the typeform form for the test (typeform id). */
	typeformTestId: string
};
	["CandidateGiveFeedback"]: {
		result: string
};
	["CreateOpenQuestion"]: {
		uuid: ModelTypes["UUID"],
	content: string
};
	["UpdateOpenQuestion"]: {
		uuid: ModelTypes["UUID"],
	content: string
};
	["DeleteOpenQuestion"]: {
		result: string
};
	["RecruiterStarSolution"]: {
		result: string
};
	["StarSolution"]: {
	solutionId: ModelTypes["UUID"],
	starred: boolean
};
	["RecruiterSeeSolution"]: {
		result: string
};
	["SeeSolution"]: {
	solutionId: ModelTypes["UUID"]
};
	["RecruiterArchiveTest"]: {
		result: string
};
	["RecruiterDeleteTest"]: {
		result: string
};
	["SignUp"]: {
		payload?: ModelTypes["RecruiterSignUpPayload"] | undefined
};
	["RecruiterSignUpPayload"]: {
		status: string,
	user?: ModelTypes["SignUpUser"] | undefined,
	errors: Array<ModelTypes["Error"] | undefined>
};
	["SignUpUser"]: {
		email: string,
	uuid: ModelTypes["UUID"],
	accountId: number
};
	["Error"]: {
		name: string,
	messages?: Array<string | undefined> | undefined
};
	["SignUpInput"]: {
	email: string,
	firstName: string,
	lastName: string,
	locale: ModelTypes["Locale"],
	role: ModelTypes["RecruiterRole"],
	phone?: string | undefined
};
	["SendResetPassword"]: {
		result: string
};
	["SendResetPasswordInput"]: {
	email: string
};
	["RecruiterWizardCreateTest"]: {
		test?: ModelTypes["WizardTest"] | undefined
};
	["WizardTest"]: {
		id: ModelTypes["UUID"],
	title: string
};
	["WizardCreateTestInput"]: {
	roleName: string,
	competencies: Array<ModelTypes["WizardCompetencyInput"] | undefined>,
	existingOpenQuestions: Array<ModelTypes["ExistingOpenQuestion"] | undefined>,
	newOpenQuestions: Array<ModelTypes["NewOpenQuestion"] | undefined>,
	testType: ModelTypes["TestType"],
	testLevel: ModelTypes["TestLevel"],
	description?: string | undefined
};
	["ExistingOpenQuestion"]: {
	id: ModelTypes["UUID"]
};
	["NewOpenQuestion"]: {
	text: string
};
	["RecruiterMutation"]: {
		updateCandidatesStatus?: ModelTypes["UpdateCandidatesStatus"] | undefined,
	/** Recruiter Sign Up */
	signUp?: ModelTypes["SignUp"] | undefined
};
	["UpdateCandidatesStatus"]: {
		candidates: Array<ModelTypes["SolutionCandidate"] | undefined>
};
	["UpdateCandidatesStatusInput"]: {
	candidateIds: Array<ModelTypes["UUID"] | undefined>,
	status: ModelTypes["CandidateStatusInput"]
};
	["CandidateStatusInput"]:CandidateStatusInput;
	["CandidateMutation"]: {
		startSolution?: ModelTypes["StartSolution"] | undefined,
	seeQuestion?: ModelTypes["SeeQuestion"] | undefined,
	submitResponse?: ModelTypes["SubmitResponse"] | undefined,
	submitSolution?: ModelTypes["SubmitSolution"] | undefined
};
	["StartSolution"]: {
		solutionAttempt: ModelTypes["SolutionAttempt"]
};
	["SolutionAttempt"]: {
		solutionId: ModelTypes["UUID"],
	questions: Array<ModelTypes["TestQuestion"] | undefined>,
	availableTime: number,
	suggestedTime: number
};
	["TestQuestion"]:ModelTypes["SingleChoiceQuestion"] | ModelTypes["MultipleChoiceQuestion"] | ModelTypes["NumericQuestion"] | ModelTypes["TextQuestion"];
	["SingleChoiceQuestion"]: {
		uuid: ModelTypes["UUID"],
	text: string,
	possibleAnswers: Array<ModelTypes["QuestionAnswer"] | undefined>
};
	["BaseTestQuestion"]: ModelTypes["SingleChoiceQuestion"] | ModelTypes["MultipleChoiceQuestion"] | ModelTypes["NumericQuestion"] | ModelTypes["TextQuestion"];
	["BaseTestQuestionWithAnswers"]: ModelTypes["SingleChoiceQuestion"] | ModelTypes["MultipleChoiceQuestion"];
	["QuestionAnswer"]: {
		uuid: ModelTypes["UUID"],
	text: string
};
	["MultipleChoiceQuestion"]: {
		uuid: ModelTypes["UUID"],
	text: string,
	possibleAnswers: Array<ModelTypes["QuestionAnswer"] | undefined>
};
	["NumericQuestion"]: {
		uuid: ModelTypes["UUID"],
	text: string
};
	["TextQuestion"]: {
		uuid: ModelTypes["UUID"],
	text: string
};
	["StartSolutionInput"]: {
	candidateSessionToken: string
};
	["SeeQuestion"]: {
		result: string
};
	["SeeQuestionInput"]: {
	questionId: ModelTypes["UUID"],
	solutionId: ModelTypes["UUID"]
};
	["SubmitResponse"]: {
		result: string
};
	["SubmitResponseInput"]: {
	questionId: ModelTypes["UUID"],
	solutionId: ModelTypes["UUID"],
	answersGiven: Array<ModelTypes["AnswerGivenInput"]>
};
	["AnswerGivenInput"]: {
	text?: string | undefined,
	answerId?: ModelTypes["UUID"] | undefined
};
	["SubmitSolution"]: {
		result: string
};
	["SubmitSolutionInput"]: {
	solutionId: ModelTypes["UUID"]
}
    }

export type GraphQLTypes = {
    ["RootQuery"]: {
	__typename: "RootQuery",
	adminTestConnection?: GraphQLTypes["AdminTestConnection"] | undefined,
	adminQuestionConnection?: GraphQLTypes["AdminQuestionConnection"] | undefined,
	adminUserConnection?: GraphQLTypes["AdminUserConnection"] | undefined,
	adminSolutionConnection?: GraphQLTypes["AdminSolutionConnection"] | undefined,
	adminTest?: GraphQLTypes["AdminTest"] | undefined,
	adminUser?: GraphQLTypes["AdminBaseUser"] | undefined,
	adminQuestion?: GraphQLTypes["Question"] | undefined,
	hello?: string | undefined,
	error?: string | undefined,
	competencyConnection?: GraphQLTypes["CompetencyConnection"] | undefined,
	testConnection?: GraphQLTypes["TestConnection"] | undefined,
	solutionConnection?: GraphQLTypes["SolutionConnection"] | undefined,
	testSolutionConnection?: GraphQLTypes["SolutionConnection"] | undefined,
	solution?: GraphQLTypes["Solution"] | undefined,
	test?: GraphQLTypes["Test"] | undefined,
	candidate?: GraphQLTypes["Candidate"] | undefined,
	/** Preview Test details with just a test uuid as a candidate. */
	candidateTestPreview?: GraphQLTypes["TestPreviewResult"] | undefined,
	/** Preview Solution details with a session token as a candidate. */
	candidateSolutionPreview?: GraphQLTypes["CandidateSolutionPreview"] | undefined,
	me?: GraphQLTypes["BaseUser"] | undefined,
	/** Preview Candidate details with a candidate session token. */
	candidatePreview?: GraphQLTypes["CandidatePreview"] | undefined,
	suggestedCompetencies: GraphQLTypes["SuggestedCompetencies"],
	supportedCompetencies?: GraphQLTypes["CompetencyConnection"] | undefined,
	suggestedOpenQuestions: GraphQLTypes["SuggestedOpenQuestions"]
};
	["AdminTestConnection"]: {
	__typename: "AdminTestConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["AdminTestEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
["PageInfo"]: {
	__typename: "PageInfo",
	/** When paginating forwards, are there more items? */
	hasNextPage: boolean,
	/** When paginating backwards, are there more items? */
	hasPreviousPage: boolean,
	/** When paginating backwards, the cursor to continue. */
	startCursor?: string | undefined,
	/** When paginating forwards, the cursor to continue. */
	endCursor?: string | undefined
};
	/** A Relay edge containing a `AdminTest` and its cursor. */
["AdminTestEdge"]: {
	__typename: "AdminTestEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["AdminBaseTest"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["AdminBaseTest"]: {
	__typename: "AdminBaseTest",
	uuid: GraphQLTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<GraphQLTypes["Competency"] | undefined> | undefined,
	locale: GraphQLTypes["Locale"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivityAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: GraphQLTypes["Seniority"],
	testState: GraphQLTypes["TestState"],
	testType: GraphQLTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: GraphQLTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	assignedTo?: GraphQLTypes["AdminBaseUser"] | undefined,
	solutionsDistribution: Array<GraphQLTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questionsNo: number,
	totalTime: number
};
	/** Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
in fields, resolvers and input. */
["UUID"]: "scalar" & { name: "UUID" };
	["Competency"]: {
	__typename: "Competency",
	uuid: GraphQLTypes["UUID"],
	name: string,
	domain: string,
	icon: GraphQLTypes["Icon"],
	description: string
};
	/** An enumeration. */
["Icon"]: Icon;
	/** An enumeration. */
["Locale"]: Locale;
	/** The `DateTime` scalar type represents a DateTime
value as specified by
[iso8601](https://en.wikipedia.org/wiki/ISO_8601). */
["DateTime"]: "scalar" & { name: "DateTime" };
	/** An enumeration. */
["Seniority"]: Seniority;
	/** An enumeration. */
["TestState"]: TestState;
	/** An enumeration. */
["TestType"]: TestType;
	/** The `Decimal` scalar type represents a python Decimal. */
["Decimal"]: "scalar" & { name: "Decimal" };
	["AdminBaseUser"]: {
	__typename: "AdminBaseUser",
	uuid: GraphQLTypes["UUID"],
	firstName: string,
	lastName: string,
	organizationName?: string | undefined,
	email: string,
	isAdmin: boolean,
	locale: GraphQLTypes["Locale"],
	accountId: number,
	role?: GraphQLTypes["RecruiterRole"] | undefined,
	logUrl: string,
	accountLogUrl: string,
	blocked: boolean
};
	/** An enumeration. */
["RecruiterRole"]: RecruiterRole;
	["Bin"]: {
	__typename: "Bin",
	label: string,
	value: number
};
	["AdminQuestionConnection"]: {
	__typename: "AdminQuestionConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["AdminQuestionEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `AdminQuestion` and its cursor. */
["AdminQuestionEdge"]: {
	__typename: "AdminQuestionEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["Question"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["Question"]: {
	__typename: "Question",
	uuid: GraphQLTypes["UUID"],
	competency?: GraphQLTypes["Competency"] | undefined,
	custom: boolean,
	questionType: GraphQLTypes["QuestionType"],
	answers?: Array<GraphQLTypes["Answer"] | undefined> | undefined,
	content: string,
	appendix?: string | undefined,
	availableTime: number,
	difficulty: GraphQLTypes["Difficulty"],
	questionStatus: GraphQLTypes["QuestionStatus"],
	requiresExternalTool?: string | undefined
};
	/** An enumeration. */
["QuestionType"]: QuestionType;
	["Answer"]: {
	__typename: "Answer",
	uuid: GraphQLTypes["UUID"],
	correct: boolean,
	content: string
};
	/** An enumeration. */
["Difficulty"]: Difficulty;
	/** An enumeration. */
["QuestionStatus"]: QuestionStatus;
	["AdminUserConnection"]: {
	__typename: "AdminUserConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["AdminUserEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `AdminUser` and its cursor. */
["AdminUserEdge"]: {
	__typename: "AdminUserEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["BaseUser"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["BaseUser"]: {
	__typename: "BaseUser",
	uuid: GraphQLTypes["UUID"],
	firstName: string,
	lastName: string,
	organizationName?: string | undefined,
	email: string,
	isAdmin: boolean,
	locale: GraphQLTypes["Locale"],
	accountId: number,
	role?: GraphQLTypes["RecruiterRole"] | undefined,
	logUrl: string,
	accountLogUrl: string
};
	["AdminSolutionConnection"]: {
	__typename: "AdminSolutionConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["AdminSolutionEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `AdminSolution` and its cursor. */
["AdminSolutionEdge"]: {
	__typename: "AdminSolutionEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["Solution"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["Solution"]: {
	__typename: "Solution",
	candidate: GraphQLTypes["SolutionCandidate"],
	uuid: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	seenAt?: GraphQLTypes["DateTime"] | undefined,
	submittedAt: GraphQLTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: GraphQLTypes["SolutionState"],
	score: GraphQLTypes["Decimal"],
	timeSpent: number,
	summary?: Array<GraphQLTypes["SummaryEntry"] | undefined> | undefined,
	test: GraphQLTypes["BaseTest"],
	responses?: Array<GraphQLTypes["Response"] | undefined> | undefined
};
	["SolutionCandidate"]: {
	__typename: "SolutionCandidate",
	uuid: GraphQLTypes["UUID"],
	firstName: string,
	lastName: string,
	email: string,
	phoneNumber: string,
	status: GraphQLTypes["CandidateStatus"]
};
	/** An enumeration. */
["CandidateStatus"]: CandidateStatus;
	/** An enumeration. */
["SolutionState"]: SolutionState;
	["SummaryEntry"]: {
	__typename: "SummaryEntry",
	competency: GraphQLTypes["SummaryCompetency"],
	numberOfQuestions: number,
	score: GraphQLTypes["Decimal"]
};
	["SummaryCompetency"]: {
	__typename: "SummaryCompetency",
	uuid: GraphQLTypes["UUID"],
	name: string
};
	["BaseTest"]: {
	__typename: "BaseTest",
	uuid: GraphQLTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<GraphQLTypes["Competency"] | undefined> | undefined,
	locale: GraphQLTypes["Locale"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivityAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: GraphQLTypes["Seniority"],
	testState: GraphQLTypes["TestState"],
	testType: GraphQLTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: GraphQLTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	/** Owner of the test (HR user / organization) */
	assignedTo?: GraphQLTypes["BaseUser"] | undefined,
	solutionsDistribution: Array<GraphQLTypes["Bin"] | undefined>,
	experimental?: boolean | undefined
};
	["Response"]: {
	__typename: "Response",
	uuid: GraphQLTypes["UUID"],
	index?: number | undefined,
	question?: GraphQLTypes["Question"] | undefined,
	answersGiven?: Array<GraphQLTypes["AnswerGiven"] | undefined> | undefined,
	score?: GraphQLTypes["Decimal"] | undefined,
	outdated: boolean
};
	["AnswerGiven"]: {
	__typename: "AnswerGiven",
	uuid: GraphQLTypes["UUID"],
	answer?: GraphQLTypes["Answer"] | undefined,
	correct?: boolean | undefined,
	userSelected: boolean,
	content?: string | undefined
};
	["AdminTest"]: {
	__typename: "AdminTest",
	uuid: GraphQLTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<GraphQLTypes["Competency"] | undefined> | undefined,
	locale: GraphQLTypes["Locale"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivityAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: GraphQLTypes["Seniority"],
	testState: GraphQLTypes["TestState"],
	testType: GraphQLTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: GraphQLTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	assignedTo?: GraphQLTypes["AdminBaseUser"] | undefined,
	solutionsDistribution: Array<GraphQLTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questionsNo: number,
	totalTime: number,
	questions?: Array<GraphQLTypes["Question"] | undefined> | undefined,
	averageSummary?: Array<GraphQLTypes["SummaryEntry"] | undefined> | undefined,
	solutionUuids?: Array<GraphQLTypes["UUID"] | undefined> | undefined
};
	["CompetencyConnection"]: {
	__typename: "CompetencyConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["CompetencyEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `Competency` and its cursor. */
["CompetencyEdge"]: {
	__typename: "CompetencyEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["Competency"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["TestConnection"]: {
	__typename: "TestConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["TestEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `Test` and its cursor. */
["TestEdge"]: {
	__typename: "TestEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["BaseTest"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["SolutionConnection"]: {
	__typename: "SolutionConnection",
	/** Pagination data for this connection. */
	pageInfo: GraphQLTypes["PageInfo"],
	/** Contains the nodes in this connection. */
	edges: Array<GraphQLTypes["SolutionEdge"] | undefined>,
	/** Total count of available results */
	totalCount: number
};
	/** A Relay edge containing a `Solution` and its cursor. */
["SolutionEdge"]: {
	__typename: "SolutionEdge",
	/** The item at the end of the edge */
	node?: GraphQLTypes["BaseSolution"] | undefined,
	/** A cursor for use in pagination */
	cursor: string
};
	["BaseSolution"]: {
	__typename: "BaseSolution",
	candidate: GraphQLTypes["SolutionCandidate"],
	uuid: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	seenAt?: GraphQLTypes["DateTime"] | undefined,
	submittedAt: GraphQLTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: GraphQLTypes["SolutionState"],
	score: GraphQLTypes["Decimal"],
	timeSpent: number,
	summary?: Array<GraphQLTypes["SummaryEntry"] | undefined> | undefined
};
	["Test"]: {
	__typename: "Test",
	uuid: GraphQLTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<GraphQLTypes["Competency"] | undefined> | undefined,
	locale: GraphQLTypes["Locale"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivityAt?: GraphQLTypes["DateTime"] | undefined,
	mostRecentActivitySecondsAgo?: number | undefined,
	disabledAt?: GraphQLTypes["DateTime"] | undefined,
	typeformTestId?: string | undefined,
	seniority: GraphQLTypes["Seniority"],
	testState: GraphQLTypes["TestState"],
	testType: GraphQLTypes["TestType"],
	/** Number of submitted solutions to the test */
	completedBy: number,
	/** Average time in seconds spent on solutions */
	averageSolutionTime?: number | undefined,
	/** Average score of solutions */
	averageSolutionScore?: GraphQLTypes["Decimal"] | undefined,
	competencyNames: Array<string | undefined>,
	/** Owner of the test (HR user / organization) */
	assignedTo?: GraphQLTypes["BaseUser"] | undefined,
	solutionsDistribution: Array<GraphQLTypes["Bin"] | undefined>,
	experimental?: boolean | undefined,
	questions?: Array<GraphQLTypes["Question"] | undefined> | undefined,
	averageSummary?: Array<GraphQLTypes["SummaryEntry"] | undefined> | undefined,
	solutionUuids?: Array<GraphQLTypes["UUID"] | undefined> | undefined
};
	["Candidate"]: {
	__typename: "Candidate",
	uuid: GraphQLTypes["UUID"],
	phoneNumber?: string | undefined,
	firstName: string,
	lastName: string,
	email: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt?: GraphQLTypes["DateTime"] | undefined,
	disabled?: boolean | undefined,
	assessments?: Array<GraphQLTypes["Assessment"] | undefined> | undefined,
	solutions?: Array<GraphQLTypes["BaseSolution"] | undefined> | undefined
};
	["Assessment"]: {
	__typename: "Assessment",
	candidate: GraphQLTypes["SolutionCandidate"],
	uuid: GraphQLTypes["UUID"],
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	seenAt?: GraphQLTypes["DateTime"] | undefined,
	submittedAt: GraphQLTypes["DateTime"],
	testRoleName: string,
	starred: boolean,
	solutionState: GraphQLTypes["SolutionState"],
	score: GraphQLTypes["Decimal"],
	timeSpent: number,
	summary?: Array<GraphQLTypes["SummaryEntry"] | undefined> | undefined,
	test: GraphQLTypes["BaseTest"],
	responses?: Array<GraphQLTypes["Response"] | undefined> | undefined
};
	["TestPreviewResult"]:{
        	__typename:"TestPreview" | "TestPreviewUnavailable"
        	['...on TestPreview']: '__union' & GraphQLTypes["TestPreview"];
	['...on TestPreviewUnavailable']: '__union' & GraphQLTypes["TestPreviewUnavailable"];
};
	["TestPreview"]: {
	__typename: "TestPreview",
	uuid: GraphQLTypes["UUID"],
	title: string,
	roleName: string,
	availableTime: number,
	numberOfQuestions: number,
	organizationName: string,
	requiresExternalTool: boolean,
	description?: string | undefined,
	competencies?: Array<GraphQLTypes["Competency"] | undefined> | undefined,
	locale: GraphQLTypes["Locale"]
};
	["TestPreviewUnavailable"]: {
	__typename: "TestPreviewUnavailable",
	reason: string
};
	["CandidateSolutionPreview"]: {
	__typename: "CandidateSolutionPreview",
	test: GraphQLTypes["TestPreview"],
	solutionState: GraphQLTypes["SolutionState"],
	candidate: GraphQLTypes["CandidatePreview"],
	score?: GraphQLTypes["Decimal"] | undefined,
	timeSpent?: number | undefined,
	summary?: Array<GraphQLTypes["SummaryEntry"] | undefined> | undefined
};
	["CandidatePreview"]: {
	__typename: "CandidatePreview",
	uuid: GraphQLTypes["UUID"],
	phoneNumber?: string | undefined,
	firstName: string,
	lastName: string,
	email: string
};
	["SuggestedCompetencies"]: {
	__typename: "SuggestedCompetencies",
	selected: Array<GraphQLTypes["SuggestedCompetency"] | undefined>
};
	["SuggestedCompetency"]: {
	__typename: "SuggestedCompetency",
	uuid: GraphQLTypes["UUID"],
	name: string,
	domain: string,
	icon: GraphQLTypes["Icon"],
	description: string
};
	["PositionInput"]: {
		jobTitle: string,
	seniority?: GraphQLTypes["Seniority"] | undefined,
	testLevel: GraphQLTypes["TestLevel"],
	testType: GraphQLTypes["TestType"],
	description?: string | undefined
};
	/** An enumeration. */
["TestLevel"]: TestLevel;
	["SuggestedOpenQuestions"]: {
	__typename: "SuggestedOpenQuestions",
	suggested: Array<GraphQLTypes["SuggestedOpenQuestion"] | undefined>
};
	["SuggestedOpenQuestion"]: {
	__typename: "SuggestedOpenQuestion",
	competencyName: string,
	content: string,
	uuid: GraphQLTypes["UUID"]
};
	["WizardCompetencyInput"]: {
		id: GraphQLTypes["UUID"]
};
	["MutationsRoot"]: {
	__typename: "MutationsRoot",
	hero?: GraphQLTypes["HeroTracking"] | undefined,
	/** Mutation for testing purposes */
	test?: GraphQLTypes["TestMutation"] | undefined,
	/** Authenticate using an email and password */
	auth?: GraphQLTypes["Auth"] | undefined,
	/** Reset password using a password reset token */
	resetPassword?: GraphQLTypes["ResetPassword"] | undefined,
	/** Manually create a test draft as an admin */
	adminCreateTest?: GraphQLTypes["CreateTest"] | undefined,
	/** Update a test draft as an admin */
	adminUpdateTest?: GraphQLTypes["UpdateTest"] | undefined,
	/** Publish a test draft as an admin */
	adminPublishTest?: GraphQLTypes["PublishTest"] | undefined,
	/** Disable an active test as an admin */
	adminDisableTest?: GraphQLTypes["DisableTest"] | undefined,
	/** Enable an inactive test as an admin */
	adminEnableTest?: GraphQLTypes["EnableTest"] | undefined,
	/** Delete a test as an admin */
	adminDeleteTest?: GraphQLTypes["DeleteTest"] | undefined,
	/** Duplicate a test in any state to a new draft */
	adminDuplicateTest?: GraphQLTypes["DuplicateTest"] | undefined,
	/** Create a HR user, acquiring a password reset token */
	adminCreateUser?: GraphQLTypes["CreateUser"] | undefined,
	/** Update a HR user */
	adminUpdateUser?: GraphQLTypes["UpdateUser"] | undefined,
	/** Delete a user as an admin */
	adminDeleteUser?: GraphQLTypes["DeleteUser"] | undefined,
	/** Toggle user block as an admin */
	adminToggleBlockUser?: GraphQLTypes["ToggleBlockUser"] | undefined,
	/** Acquire a password reset token for an existing user */
	adminTriggerPasswordReset?: GraphQLTypes["TriggerPasswordReset"] | undefined,
	/** Acquire an authorization token to impersonate an existing user */
	adminImpersonateUser?: GraphQLTypes["ImpersonateUser"] | undefined,
	/** Issue 2FA token for phone number verification as a candidate. */
	candidateIssue2faToken?: GraphQLTypes["Issue2FAToken"] | undefined,
	candidateGet2faToken?: GraphQLTypes["Get2FAToken"] | undefined,
	/** Verify a 2FA token and receive an intermediate token as a candidate. */
	candidateVerify2faToken?: GraphQLTypes["Verify2FAToken"] | undefined,
	/** Register as a candidate using an intermediate token from 2FA verification. */
	registerCandidate?: GraphQLTypes["RegisterCandidate"] | undefined,
	/** Attempt solution as a candidate using a session token. */
	candidateAttemptSolution?: GraphQLTypes["CandidateAttemptSolution"] | undefined,
	/** Give feedback as a candidate using a session token. */
	candidateGiveFeedback?: GraphQLTypes["CandidateGiveFeedback"] | undefined,
	/** Create an open question */
	adminCreateOpenQuestion?: GraphQLTypes["CreateOpenQuestion"] | undefined,
	/** Update an open question */
	adminUpdateOpenQuestion?: GraphQLTypes["UpdateOpenQuestion"] | undefined,
	/** Delete an open question */
	adminDeleteOpenQuestion?: GraphQLTypes["DeleteOpenQuestion"] | undefined,
	/** Recruiter star a solution */
	recruiterStarSolution?: GraphQLTypes["RecruiterStarSolution"] | undefined,
	/** Recruiter see a solution */
	recruiterSeeSolution?: GraphQLTypes["RecruiterSeeSolution"] | undefined,
	/** Recruiter archive a test */
	recruiterArchiveTest?: GraphQLTypes["RecruiterArchiveTest"] | undefined,
	/** Recruiter delete a test */
	recruiterDeleteTest?: GraphQLTypes["RecruiterDeleteTest"] | undefined,
	/** Recruiter Sign Up */
	recruiterSignUp?: GraphQLTypes["SignUp"] | undefined,
	/** Send reset password */
	sendResetPassword?: GraphQLTypes["SendResetPassword"] | undefined,
	/** Wizard create a test */
	wizardCreateTest?: GraphQLTypes["RecruiterWizardCreateTest"] | undefined,
	recruiter?: GraphQLTypes["RecruiterMutation"] | undefined,
	candidate?: GraphQLTypes["CandidateMutation"] | undefined
};
	["HeroTracking"]: {
	__typename: "HeroTracking",
	result: string
};
	["TrackInput"]: {
		eventName: string,
	properties?: GraphQLTypes["JSONString"] | undefined
};
	/** Allows use of a JSON String for input / output from the GraphQL schema.

Use of this type is *not recommended* as you lose the benefits of having a defined, static
schema (one of the key benefits of GraphQL). */
["JSONString"]: "scalar" & { name: "JSONString" };
	["TestMutation"]: {
	__typename: "TestMutation",
	result: string
};
	["Auth"]: {
	__typename: "Auth",
	authorizationToken: string
};
	["ResetPassword"]: {
	__typename: "ResetPassword",
	authorizationToken: string
};
	["CreateTest"]: {
	__typename: "CreateTest",
	result?: GraphQLTypes["AdminTest"] | undefined
};
	["UpdateTest"]: {
	__typename: "UpdateTest",
	result?: GraphQLTypes["AdminTest"] | undefined
};
	["PublishTest"]: {
	__typename: "PublishTest",
	result?: GraphQLTypes["AdminTest"] | undefined
};
	["DisableTest"]: {
	__typename: "DisableTest",
	result?: GraphQLTypes["AdminTest"] | undefined
};
	["EnableTest"]: {
	__typename: "EnableTest",
	result?: GraphQLTypes["AdminTest"] | undefined
};
	["DeleteTest"]: {
	__typename: "DeleteTest",
	result?: GraphQLTypes["AdminTest"] | undefined
};
	["DuplicateTest"]: {
	__typename: "DuplicateTest",
	result?: GraphQLTypes["AdminTest"] | undefined
};
	["CreateUser"]: {
	__typename: "CreateUser",
	resetToken: string,
	user: GraphQLTypes["AdminBaseUser"]
};
	["UpdateUser"]: {
	__typename: "UpdateUser",
	user: GraphQLTypes["AdminBaseUser"]
};
	["DeleteUser"]: {
	__typename: "DeleteUser",
	result?: GraphQLTypes["AdminBaseUser"] | undefined
};
	["ToggleBlockUser"]: {
	__typename: "ToggleBlockUser",
	result?: GraphQLTypes["AdminBaseUser"] | undefined
};
	["TriggerPasswordReset"]: {
	__typename: "TriggerPasswordReset",
	resetToken: string
};
	["ImpersonateUser"]: {
	__typename: "ImpersonateUser",
	/** Authorization token of the user with impersonation metadata */
	authorizationToken: string
};
	["Issue2FAToken"]: {
	__typename: "Issue2FAToken",
	result: string
};
	["Get2FAToken"]: {
	__typename: "Get2FAToken",
	intermediateToken: string
};
	["Verify2FAToken"]: {
	__typename: "Verify2FAToken",
	intermediateToken: string
};
	["RegisterCandidate"]: {
	__typename: "RegisterCandidate",
	/** Candidate's session token. */
	candidateSessionToken: string
};
	["CandidateAttemptSolution"]: {
	__typename: "CandidateAttemptSolution",
	/** Id of the typeform form for the test (typeform id). */
	typeformTestId: string
};
	["CandidateGiveFeedback"]: {
	__typename: "CandidateGiveFeedback",
	result: string
};
	["CreateOpenQuestion"]: {
	__typename: "CreateOpenQuestion",
	uuid: GraphQLTypes["UUID"],
	content: string
};
	["UpdateOpenQuestion"]: {
	__typename: "UpdateOpenQuestion",
	uuid: GraphQLTypes["UUID"],
	content: string
};
	["DeleteOpenQuestion"]: {
	__typename: "DeleteOpenQuestion",
	result: string
};
	["RecruiterStarSolution"]: {
	__typename: "RecruiterStarSolution",
	result: string
};
	["StarSolution"]: {
		solutionId: GraphQLTypes["UUID"],
	starred: boolean
};
	["RecruiterSeeSolution"]: {
	__typename: "RecruiterSeeSolution",
	result: string
};
	["SeeSolution"]: {
		solutionId: GraphQLTypes["UUID"]
};
	["RecruiterArchiveTest"]: {
	__typename: "RecruiterArchiveTest",
	result: string
};
	["RecruiterDeleteTest"]: {
	__typename: "RecruiterDeleteTest",
	result: string
};
	["SignUp"]: {
	__typename: "SignUp",
	payload?: GraphQLTypes["RecruiterSignUpPayload"] | undefined
};
	["RecruiterSignUpPayload"]: {
	__typename: "RecruiterSignUpPayload",
	status: string,
	user?: GraphQLTypes["SignUpUser"] | undefined,
	errors: Array<GraphQLTypes["Error"] | undefined>
};
	["SignUpUser"]: {
	__typename: "SignUpUser",
	email: string,
	uuid: GraphQLTypes["UUID"],
	accountId: number
};
	["Error"]: {
	__typename: "Error",
	name: string,
	messages?: Array<string | undefined> | undefined
};
	["SignUpInput"]: {
		email: string,
	firstName: string,
	lastName: string,
	locale: GraphQLTypes["Locale"],
	role: GraphQLTypes["RecruiterRole"],
	phone?: string | undefined
};
	["SendResetPassword"]: {
	__typename: "SendResetPassword",
	result: string
};
	["SendResetPasswordInput"]: {
		email: string
};
	["RecruiterWizardCreateTest"]: {
	__typename: "RecruiterWizardCreateTest",
	test?: GraphQLTypes["WizardTest"] | undefined
};
	["WizardTest"]: {
	__typename: "WizardTest",
	id: GraphQLTypes["UUID"],
	title: string
};
	["WizardCreateTestInput"]: {
		roleName: string,
	competencies: Array<GraphQLTypes["WizardCompetencyInput"] | undefined>,
	existingOpenQuestions: Array<GraphQLTypes["ExistingOpenQuestion"] | undefined>,
	newOpenQuestions: Array<GraphQLTypes["NewOpenQuestion"] | undefined>,
	testType: GraphQLTypes["TestType"],
	testLevel: GraphQLTypes["TestLevel"],
	description?: string | undefined
};
	["ExistingOpenQuestion"]: {
		id: GraphQLTypes["UUID"]
};
	["NewOpenQuestion"]: {
		text: string
};
	["RecruiterMutation"]: {
	__typename: "RecruiterMutation",
	updateCandidatesStatus?: GraphQLTypes["UpdateCandidatesStatus"] | undefined,
	/** Recruiter Sign Up */
	signUp?: GraphQLTypes["SignUp"] | undefined
};
	["UpdateCandidatesStatus"]: {
	__typename: "UpdateCandidatesStatus",
	candidates: Array<GraphQLTypes["SolutionCandidate"] | undefined>
};
	["UpdateCandidatesStatusInput"]: {
		candidateIds: Array<GraphQLTypes["UUID"] | undefined>,
	status: GraphQLTypes["CandidateStatusInput"]
};
	/** An enumeration. */
["CandidateStatusInput"]: CandidateStatusInput;
	["CandidateMutation"]: {
	__typename: "CandidateMutation",
	startSolution?: GraphQLTypes["StartSolution"] | undefined,
	seeQuestion?: GraphQLTypes["SeeQuestion"] | undefined,
	submitResponse?: GraphQLTypes["SubmitResponse"] | undefined,
	submitSolution?: GraphQLTypes["SubmitSolution"] | undefined
};
	["StartSolution"]: {
	__typename: "StartSolution",
	solutionAttempt: GraphQLTypes["SolutionAttempt"]
};
	["SolutionAttempt"]: {
	__typename: "SolutionAttempt",
	solutionId: GraphQLTypes["UUID"],
	questions: Array<GraphQLTypes["TestQuestion"] | undefined>,
	availableTime: number,
	suggestedTime: number
};
	["TestQuestion"]:{
        	__typename:"SingleChoiceQuestion" | "MultipleChoiceQuestion" | "NumericQuestion" | "TextQuestion"
        	['...on SingleChoiceQuestion']: '__union' & GraphQLTypes["SingleChoiceQuestion"];
	['...on MultipleChoiceQuestion']: '__union' & GraphQLTypes["MultipleChoiceQuestion"];
	['...on NumericQuestion']: '__union' & GraphQLTypes["NumericQuestion"];
	['...on TextQuestion']: '__union' & GraphQLTypes["TextQuestion"];
};
	["SingleChoiceQuestion"]: {
	__typename: "SingleChoiceQuestion",
	uuid: GraphQLTypes["UUID"],
	text: string,
	possibleAnswers: Array<GraphQLTypes["QuestionAnswer"] | undefined>
};
	["BaseTestQuestion"]: {
	__typename:"SingleChoiceQuestion" | "MultipleChoiceQuestion" | "NumericQuestion" | "TextQuestion",
	uuid: GraphQLTypes["UUID"],
	text: string
	['...on SingleChoiceQuestion']: '__union' & GraphQLTypes["SingleChoiceQuestion"];
	['...on MultipleChoiceQuestion']: '__union' & GraphQLTypes["MultipleChoiceQuestion"];
	['...on NumericQuestion']: '__union' & GraphQLTypes["NumericQuestion"];
	['...on TextQuestion']: '__union' & GraphQLTypes["TextQuestion"];
};
	["BaseTestQuestionWithAnswers"]: {
	__typename:"SingleChoiceQuestion" | "MultipleChoiceQuestion",
	possibleAnswers: Array<GraphQLTypes["QuestionAnswer"] | undefined>
	['...on SingleChoiceQuestion']: '__union' & GraphQLTypes["SingleChoiceQuestion"];
	['...on MultipleChoiceQuestion']: '__union' & GraphQLTypes["MultipleChoiceQuestion"];
};
	["QuestionAnswer"]: {
	__typename: "QuestionAnswer",
	uuid: GraphQLTypes["UUID"],
	text: string
};
	["MultipleChoiceQuestion"]: {
	__typename: "MultipleChoiceQuestion",
	uuid: GraphQLTypes["UUID"],
	text: string,
	possibleAnswers: Array<GraphQLTypes["QuestionAnswer"] | undefined>
};
	["NumericQuestion"]: {
	__typename: "NumericQuestion",
	uuid: GraphQLTypes["UUID"],
	text: string
};
	["TextQuestion"]: {
	__typename: "TextQuestion",
	uuid: GraphQLTypes["UUID"],
	text: string
};
	["StartSolutionInput"]: {
		candidateSessionToken: string
};
	["SeeQuestion"]: {
	__typename: "SeeQuestion",
	result: string
};
	["SeeQuestionInput"]: {
		questionId: GraphQLTypes["UUID"],
	solutionId: GraphQLTypes["UUID"]
};
	["SubmitResponse"]: {
	__typename: "SubmitResponse",
	result: string
};
	["SubmitResponseInput"]: {
		questionId: GraphQLTypes["UUID"],
	solutionId: GraphQLTypes["UUID"],
	answersGiven: Array<GraphQLTypes["AnswerGivenInput"]>
};
	["AnswerGivenInput"]: {
		text?: string | undefined,
	answerId?: GraphQLTypes["UUID"] | undefined
};
	["SubmitSolution"]: {
	__typename: "SubmitSolution",
	result: string
};
	["SubmitSolutionInput"]: {
		solutionId: GraphQLTypes["UUID"]
}
    }
/** An enumeration. */
export const enum Icon {
	ATTENTION_TO_DETAILS = "ATTENTION_TO_DETAILS",
	COGNITIVE_SKILLS = "COGNITIVE_SKILLS",
	GOOGLE_ADS = "GOOGLE_ADS",
	GOOGLE_ANALYTICS = "GOOGLE_ANALYTICS",
	MARKETING_B2B = "MARKETING_B2B"
}
/** An enumeration. */
export const enum Locale {
	EN = "EN",
	PL = "PL"
}
/** An enumeration. */
export const enum Seniority {
	JUNIOR = "JUNIOR",
	MID_SENIOR = "MID_SENIOR",
	MANAGER = "MANAGER"
}
/** An enumeration. */
export const enum TestState {
	ACTIVE = "ACTIVE",
	DISABLED = "DISABLED",
	DRAFT = "DRAFT"
}
/** An enumeration. */
export const enum TestType {
	PRE_SCREENING = "PRE_SCREENING",
	POST_CV = "POST_CV"
}
/** An enumeration. */
export const enum RecruiterRole {
	CXO = "CXO",
	HIRING_MANAGER = "HIRING_MANAGER",
	IN_HOUSE_HR = "IN_HOUSE_HR",
	HR_AGENCY = "HR_AGENCY",
	OTHER = "OTHER"
}
/** An enumeration. */
export const enum QuestionType {
	SINGLE_CHOICE = "SINGLE_CHOICE",
	MULTI_CHOICE = "MULTI_CHOICE",
	OPEN_TEXT = "OPEN_TEXT",
	CLOSED_TEXT = "CLOSED_TEXT",
	CLOSED_NUMBER = "CLOSED_NUMBER",
	FILE_UPLOAD = "FILE_UPLOAD"
}
/** An enumeration. */
export const enum Difficulty {
	EASY = "EASY",
	MEDIUM = "MEDIUM",
	HARD = "HARD"
}
/** An enumeration. */
export const enum QuestionStatus {
	SUGGESTED = "SUGGESTED",
	VALIDATION = "VALIDATION",
	READY = "READY",
	DEPRECATED = "DEPRECATED"
}
/** An enumeration. */
export const enum CandidateStatus {
	NEW = "NEW",
	SEEN = "SEEN",
	GOOD = "GOOD",
	MAYBE = "MAYBE",
	REJECTED = "REJECTED"
}
/** An enumeration. */
export const enum SolutionState {
	SENT = "SENT",
	OPENED = "OPENED",
	STARTED = "STARTED",
	SUBMITTED = "SUBMITTED",
	EXPIRED = "EXPIRED"
}
/** An enumeration. */
export const enum TestLevel {
	BASIC = "BASIC",
	ADVANCED = "ADVANCED"
}
/** An enumeration. */
export const enum CandidateStatusInput {
	GOOD = "GOOD",
	MAYBE = "MAYBE",
	REJECTED = "REJECTED"
}

type ZEUS_VARIABLES = {
	["UUID"]: ValueTypes["UUID"];
	["Icon"]: ValueTypes["Icon"];
	["Locale"]: ValueTypes["Locale"];
	["DateTime"]: ValueTypes["DateTime"];
	["Seniority"]: ValueTypes["Seniority"];
	["TestState"]: ValueTypes["TestState"];
	["TestType"]: ValueTypes["TestType"];
	["Decimal"]: ValueTypes["Decimal"];
	["RecruiterRole"]: ValueTypes["RecruiterRole"];
	["QuestionType"]: ValueTypes["QuestionType"];
	["Difficulty"]: ValueTypes["Difficulty"];
	["QuestionStatus"]: ValueTypes["QuestionStatus"];
	["CandidateStatus"]: ValueTypes["CandidateStatus"];
	["SolutionState"]: ValueTypes["SolutionState"];
	["PositionInput"]: ValueTypes["PositionInput"];
	["TestLevel"]: ValueTypes["TestLevel"];
	["WizardCompetencyInput"]: ValueTypes["WizardCompetencyInput"];
	["TrackInput"]: ValueTypes["TrackInput"];
	["JSONString"]: ValueTypes["JSONString"];
	["StarSolution"]: ValueTypes["StarSolution"];
	["SeeSolution"]: ValueTypes["SeeSolution"];
	["SignUpInput"]: ValueTypes["SignUpInput"];
	["SendResetPasswordInput"]: ValueTypes["SendResetPasswordInput"];
	["WizardCreateTestInput"]: ValueTypes["WizardCreateTestInput"];
	["ExistingOpenQuestion"]: ValueTypes["ExistingOpenQuestion"];
	["NewOpenQuestion"]: ValueTypes["NewOpenQuestion"];
	["UpdateCandidatesStatusInput"]: ValueTypes["UpdateCandidatesStatusInput"];
	["CandidateStatusInput"]: ValueTypes["CandidateStatusInput"];
	["StartSolutionInput"]: ValueTypes["StartSolutionInput"];
	["SeeQuestionInput"]: ValueTypes["SeeQuestionInput"];
	["SubmitResponseInput"]: ValueTypes["SubmitResponseInput"];
	["AnswerGivenInput"]: ValueTypes["AnswerGivenInput"];
	["SubmitSolutionInput"]: ValueTypes["SubmitSolutionInput"];
}