import "antd/dist/antd.css"
import "@/styles/antd.less"
import "../public/fonts/style.css"

import React from "react"
import { I18nextProvider } from "react-i18next"
import { ProtectedRouting } from "@/components/common/ProtectedRouting"
import { FeatureFlagsProvider } from "@/components/ui/FeatureFlags"
import { GoogleTagManger } from "@/components/ui/GoogleTagManager"
import { AuthProvider } from "@/containers/authData"
import { ApolloProvider } from "@/graphql/apollo/ApolloProvider"
import i18n from "@/i18n"
import { theme } from "@/styles/theme"
import { PageProps } from "@/utils/next"
import { ThemeProvider } from "styled-components"

export default function App(props: PageProps) {
    const { Component } = props
    const component = Component.getLayout ? Component.getLayout(props) : <LegacyAppLayout {...props} />

    return (
        <>
            <GoogleTagManger />
            <FeatureFlagsProvider>
                <ApolloProvider>
                    <AuthProvider>
                        <I18nextProvider i18n={i18n}>{component}</I18nextProvider>
                    </AuthProvider>
                </ApolloProvider>
            </FeatureFlagsProvider>
        </>
    )
}

function LegacyAppLayout({ Component, pageProps }: PageProps) {
    return (
        <ThemeProvider theme={theme}>
            <ProtectedRouting {...pageProps}>
                <style jsx global>{`
                    .ant-notification-topRight {
                        right: 50% !important;
                        transform: translateX(50%);
                        margin-right: 0px;
                    }
                    h1.ant-typography {
                        font-size: 34px;
                    }
                    .ant-typography strong {
                        font-weight: 500;
                    }
                    p,
                    span {
                        font-weight: 300;
                    }
                    body {
                        font-family: "Futura PT Book", sans-serif;
                    }
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    strong,
                    bold {
                        font-family: "Futura PT", sans-serif;
                    }
                    p {
                        margin: 0;
                    }
                    .ant-form-item {
                        margin-bottom: 20px;
                    }
                `}</style>
                <Component {...pageProps} />
                {/* Place for rendering modals from `src/components/ui/Modal`  */}
                <div id="modal-portal-target" />
            </ProtectedRouting>
        </ThemeProvider>
    )
}
