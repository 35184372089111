import { UrlObject } from "url"

type Pathname =
    | "hrDashboard"
    | "hrTests"
    | "hrNewTest"
    | "hrTest"
    | "hrTestSolution"
    | "login"
    | "reset_password"
    | "adminTests"
    | "adminUsers"
    | "adminUser"
    | "adminQuestions"
    | "adminTest"
    | "candidateTest"
    | "candidateAttempt"
    | "candidateSolutionAttempt"
    | "candidateSuccess"
    | "notFound"
    | "policyPL"
    | "policyEN"
    | "termsPL"
    | "termsEN"

type Paths = {
    [Key in Pathname]: {
        pathname: string
        relation?: {
            type: "parent"
            key: Pathname
        }
        go: (p?: { [key: string]: string }) => UrlObject
    }
}

export const paths: Paths = {
    //////////////// HR
    hrDashboard: {
        pathname: "/hr/tests",
        relation: {
            type: "parent",
            key: "hrTest",
        },
        go: () => ({
            pathname: paths.hrTests.pathname,
            query: {},
        }),
    },
    hrTests: {
        pathname: "/hr/tests",
        relation: {
            type: "parent",
            key: "hrTests",
        },
        go: () => ({
            pathname: paths.hrTests.pathname,
            query: {},
        }),
    },
    hrTest: {
        pathname: "/hr/test",
        go: ({ id }: { id: string }) => ({
            pathname: paths.hrTest.pathname,
            query: { id: id },
        }),
    },
    hrTestSolution: {
        pathname: "/hr/test",
        go: ({ id, solutionId }: { id: string; solutionId }) => ({
            pathname: paths.hrTestSolution.pathname,
            query: { id, solutionId },
        }),
    },
    hrNewTest: {
        pathname: "/hr/newTest",
        go: () => ({
            pathname: paths.hrNewTest.pathname,
            query: {},
        }),
    },
    login: {
        pathname: "/login",
        go: () => ({
            pathname: paths.login.pathname,
            query: {},
        }),
    },
    reset_password: {
        pathname: "/reset_password",
        go: () => ({
            pathname: paths.reset_password.pathname,
            query: {},
        }),
    },
    ///////// ADMIN
    adminTests: {
        pathname: "/administrador-secreto",
        relation: {
            type: "parent",
            key: "adminTest",
        },
        go: () => ({
            pathname: paths.adminTests.pathname,
            query: {},
        }),
    },
    adminUsers: {
        pathname: "/administrador-secreto/users",
        relation: {
            type: "parent",
            key: "adminUser",
        },
        go: () => ({
            pathname: paths.adminUsers.pathname,
            query: {},
        }),
    },
    adminUser: {
        pathname: "/administrador-secreto/user",
        go: ({ id }: { id: string }) => ({
            pathname: paths.adminUser.pathname,
            query: { id },
        }),
    },
    adminQuestions: {
        pathname: "/administrador-secreto/questions",
        go: () => ({
            pathname: paths.adminQuestions.pathname,
            query: {},
        }),
    },

    // todo: replace with better solution
    adminTest: {
        pathname: "/administrador-secreto/test",
        go: ({ id }: { id?: string } = {}) => ({
            pathname: paths.adminTest.pathname,
            query: id === undefined ? undefined : { id },
        }),
    },
    //////// CANDIDATE
    candidateTest: {
        pathname: "/test",
        go: ({ id }: { id?: string }) => ({
            pathname: paths.candidateTest.pathname,
            query: { id },
        }),
    },
    candidateAttempt: {
        pathname: "/attempt",
        go: ({ id }: { id: string }) => ({
            pathname: paths.candidateAttempt.pathname,
            query: { id },
        }),
    },
    candidateSolutionAttempt: {
        pathname: "/solution-attempt",
        go: ({ id }: { id: string }) => ({
            pathname: paths.candidateSolutionAttempt.pathname,
            query: { id },
        }),
    },
    candidateSuccess: {
        pathname: "/success",
        go: ({ id, candidateSessionToken }: { id: string; candidateSessionToken: string }) => ({
            pathname: paths.candidateSuccess.pathname,
            query: { id, candidateSessionToken },
        }),
    },
    notFound: {
        pathname: "/404",
        go: () => ({
            pathname: paths.notFound.pathname,
            query: {},
        }),
    },

    //EXTERNAL LINKS
    termsPL: {
        pathname: "https://docs.google.com/document/d/16VypUi_Orkn9TixUdf4VftE2KC2vqAOTEffwj3I_yt0/edit",
        go: () => ({
            pathname: paths.termsPL.pathname,
            query: {},
        }),
    },
    termsEN: {
        pathname: "https://docs.google.com/document/d/1k8ISBEIBc7QJlQlrIAPvmWl1a6b0h1RXMcadzza16vs/edit",
        go: () => ({
            pathname: paths.termsEN.pathname,
            query: {},
        }),
    },
    policyPL: {
        pathname: "https://docs.google.com/document/d/1fNQDF3krSHETZogAjCpbHmkMxJ-vsCOfd381n4js_eg/edit",
        go: () => ({
            pathname: paths.policyPL.pathname,
            query: {},
        }),
    },
    policyEN: {
        pathname: "https://docs.google.com/document/d/18NhQGQ9V4KNdCRfnZDTQD_QvjKmmU0Qm2gea6F_iFzk/edit",
        go: () => ({
            pathname: paths.policyEN.pathname,
            query: {},
        }),
    },
}
