/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	RootQuery:{
		adminTestConnection:{

		},
		adminQuestionConnection:{

		},
		adminUserConnection:{

		},
		adminSolutionConnection:{
			ids:"UUID"
		},
		adminTest:{
			uuid:"UUID"
		},
		adminUser:{
			uuid:"UUID"
		},
		adminQuestion:{
			uuid:"UUID"
		},
		error:{

		},
		competencyConnection:{

		},
		testConnection:{

		},
		solutionConnection:{
			ids:"UUID",
			orderScoreByCompetency:"UUID"
		},
		testSolutionConnection:{
			testId:"UUID"
		},
		solution:{
			uuid:"UUID"
		},
		test:{
			uuid:"UUID"
		},
		candidate:{
			uuid:"UUID"
		},
		candidateTestPreview:{
			uuid:"UUID"
		},
		candidateSolutionPreview:{

		},
		candidatePreview:{

		},
		suggestedCompetencies:{
			position:"PositionInput"
		},
		supportedCompetencies:{
			position:"PositionInput"
		},
		suggestedOpenQuestions:{
			input:"WizardCompetencyInput"
		}
	},
	UUID: `scalar.UUID` as const,
	Icon: "enum" as const,
	Locale: "enum" as const,
	DateTime: `scalar.DateTime` as const,
	Seniority: "enum" as const,
	TestState: "enum" as const,
	TestType: "enum" as const,
	Decimal: `scalar.Decimal` as const,
	RecruiterRole: "enum" as const,
	QuestionType: "enum" as const,
	Difficulty: "enum" as const,
	QuestionStatus: "enum" as const,
	CandidateStatus: "enum" as const,
	SolutionState: "enum" as const,
	PositionInput:{
		seniority:"Seniority",
		testLevel:"TestLevel",
		testType:"TestType"
	},
	TestLevel: "enum" as const,
	WizardCompetencyInput:{
		id:"UUID"
	},
	MutationsRoot:{
		hero:{
			input:"TrackInput"
		},
		test:{

		},
		auth:{

		},
		resetPassword:{

		},
		adminCreateTest:{
			questions:"UUID",
			seniority:"Seniority",
			testType:"TestType",
			user:"UUID"
		},
		adminUpdateTest:{
			questions:"UUID",
			seniority:"Seniority",
			testType:"TestType",
			testUuid:"UUID"
		},
		adminPublishTest:{
			testUuid:"UUID"
		},
		adminDisableTest:{
			testUuid:"UUID"
		},
		adminEnableTest:{
			testUuid:"UUID"
		},
		adminDeleteTest:{
			testUuid:"UUID"
		},
		adminDuplicateTest:{
			newUser:"UUID",
			testUuid:"UUID"
		},
		adminCreateUser:{
			locale:"Locale"
		},
		adminUpdateUser:{
			id:"UUID",
			locale:"Locale"
		},
		adminDeleteUser:{
			uuid:"UUID"
		},
		adminToggleBlockUser:{
			uuid:"UUID"
		},
		adminTriggerPasswordReset:{
			uuid:"UUID"
		},
		adminImpersonateUser:{
			userId:"UUID"
		},
		candidateIssue2faToken:{
			testId:"UUID"
		},
		candidateGet2faToken:{
			testId:"UUID"
		},
		candidateVerify2faToken:{
			testId:"UUID"
		},
		registerCandidate:{

		},
		candidateAttemptSolution:{

		},
		candidateGiveFeedback:{
			feedbackScore:"Decimal"
		},
		adminCreateOpenQuestion:{

		},
		adminUpdateOpenQuestion:{
			id:"UUID"
		},
		adminDeleteOpenQuestion:{
			id:"UUID"
		},
		recruiterStarSolution:{
			starSolution:"StarSolution"
		},
		recruiterSeeSolution:{
			seeSolution:"SeeSolution"
		},
		recruiterArchiveTest:{
			testId:"UUID"
		},
		recruiterDeleteTest:{
			testId:"UUID"
		},
		recruiterSignUp:{
			signUp:"SignUpInput"
		},
		sendResetPassword:{
			resetPassword:"SendResetPasswordInput"
		},
		wizardCreateTest:{
			input:"WizardCreateTestInput"
		}
	},
	TrackInput:{
		properties:"JSONString"
	},
	JSONString: `scalar.JSONString` as const,
	StarSolution:{
		solutionId:"UUID"
	},
	SeeSolution:{
		solutionId:"UUID"
	},
	SignUpInput:{
		locale:"Locale",
		role:"RecruiterRole"
	},
	SendResetPasswordInput:{

	},
	WizardCreateTestInput:{
		competencies:"WizardCompetencyInput",
		existingOpenQuestions:"ExistingOpenQuestion",
		newOpenQuestions:"NewOpenQuestion",
		testType:"TestType",
		testLevel:"TestLevel"
	},
	ExistingOpenQuestion:{
		id:"UUID"
	},
	NewOpenQuestion:{

	},
	RecruiterMutation:{
		updateCandidatesStatus:{
			input:"UpdateCandidatesStatusInput"
		},
		signUp:{
			signUp:"SignUpInput"
		}
	},
	UpdateCandidatesStatusInput:{
		candidateIds:"UUID",
		status:"CandidateStatusInput"
	},
	CandidateStatusInput: "enum" as const,
	CandidateMutation:{
		startSolution:{
			input:"StartSolutionInput"
		},
		seeQuestion:{
			input:"SeeQuestionInput"
		},
		submitResponse:{
			input:"SubmitResponseInput"
		},
		submitSolution:{
			input:"SubmitSolutionInput"
		}
	},
	StartSolutionInput:{

	},
	SeeQuestionInput:{
		questionId:"UUID",
		solutionId:"UUID"
	},
	SubmitResponseInput:{
		questionId:"UUID",
		solutionId:"UUID",
		answersGiven:"AnswerGivenInput"
	},
	AnswerGivenInput:{
		answerId:"UUID"
	},
	SubmitSolutionInput:{
		solutionId:"UUID"
	}
}

export const ReturnTypes: Record<string,any> = {
	RootQuery:{
		adminTestConnection:"AdminTestConnection",
		adminQuestionConnection:"AdminQuestionConnection",
		adminUserConnection:"AdminUserConnection",
		adminSolutionConnection:"AdminSolutionConnection",
		adminTest:"AdminTest",
		adminUser:"AdminBaseUser",
		adminQuestion:"Question",
		hello:"String",
		error:"String",
		competencyConnection:"CompetencyConnection",
		testConnection:"TestConnection",
		solutionConnection:"SolutionConnection",
		testSolutionConnection:"SolutionConnection",
		solution:"Solution",
		test:"Test",
		candidate:"Candidate",
		candidateTestPreview:"TestPreviewResult",
		candidateSolutionPreview:"CandidateSolutionPreview",
		me:"BaseUser",
		candidatePreview:"CandidatePreview",
		suggestedCompetencies:"SuggestedCompetencies",
		supportedCompetencies:"CompetencyConnection",
		suggestedOpenQuestions:"SuggestedOpenQuestions"
	},
	AdminTestConnection:{
		pageInfo:"PageInfo",
		edges:"AdminTestEdge",
		totalCount:"Int"
	},
	PageInfo:{
		hasNextPage:"Boolean",
		hasPreviousPage:"Boolean",
		startCursor:"String",
		endCursor:"String"
	},
	AdminTestEdge:{
		node:"AdminBaseTest",
		cursor:"String"
	},
	AdminBaseTest:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		organizationName:"String",
		requiresExternalTool:"Boolean",
		description:"String",
		competencies:"Competency",
		locale:"Locale",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		mostRecentActivityAt:"DateTime",
		mostRecentActivitySecondsAgo:"Int",
		disabledAt:"DateTime",
		typeformTestId:"String",
		seniority:"Seniority",
		testState:"TestState",
		testType:"TestType",
		completedBy:"Int",
		averageSolutionTime:"Int",
		averageSolutionScore:"Decimal",
		competencyNames:"String",
		assignedTo:"AdminBaseUser",
		solutionsDistribution:"Bin",
		experimental:"Boolean",
		questionsNo:"Int",
		totalTime:"Int"
	},
	UUID: `scalar.UUID` as const,
	Competency:{
		uuid:"UUID",
		name:"String",
		domain:"String",
		icon:"Icon",
		description:"String"
	},
	DateTime: `scalar.DateTime` as const,
	Decimal: `scalar.Decimal` as const,
	AdminBaseUser:{
		uuid:"UUID",
		firstName:"String",
		lastName:"String",
		organizationName:"String",
		email:"String",
		isAdmin:"Boolean",
		locale:"Locale",
		accountId:"Int",
		role:"RecruiterRole",
		logUrl:"String",
		accountLogUrl:"String",
		blocked:"Boolean"
	},
	Bin:{
		label:"String",
		value:"Int"
	},
	AdminQuestionConnection:{
		pageInfo:"PageInfo",
		edges:"AdminQuestionEdge",
		totalCount:"Int"
	},
	AdminQuestionEdge:{
		node:"Question",
		cursor:"String"
	},
	Question:{
		uuid:"UUID",
		competency:"Competency",
		custom:"Boolean",
		questionType:"QuestionType",
		answers:"Answer",
		content:"String",
		appendix:"String",
		availableTime:"Int",
		difficulty:"Difficulty",
		questionStatus:"QuestionStatus",
		requiresExternalTool:"String"
	},
	Answer:{
		uuid:"UUID",
		correct:"Boolean",
		content:"String"
	},
	AdminUserConnection:{
		pageInfo:"PageInfo",
		edges:"AdminUserEdge",
		totalCount:"Int"
	},
	AdminUserEdge:{
		node:"BaseUser",
		cursor:"String"
	},
	BaseUser:{
		uuid:"UUID",
		firstName:"String",
		lastName:"String",
		organizationName:"String",
		email:"String",
		isAdmin:"Boolean",
		locale:"Locale",
		accountId:"Int",
		role:"RecruiterRole",
		logUrl:"String",
		accountLogUrl:"String"
	},
	AdminSolutionConnection:{
		pageInfo:"PageInfo",
		edges:"AdminSolutionEdge",
		totalCount:"Int"
	},
	AdminSolutionEdge:{
		node:"Solution",
		cursor:"String"
	},
	Solution:{
		candidate:"SolutionCandidate",
		uuid:"UUID",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		seenAt:"DateTime",
		submittedAt:"DateTime",
		testRoleName:"String",
		starred:"Boolean",
		solutionState:"SolutionState",
		score:"Decimal",
		timeSpent:"Int",
		summary:"SummaryEntry",
		test:"BaseTest",
		responses:"Response"
	},
	SolutionCandidate:{
		uuid:"UUID",
		firstName:"String",
		lastName:"String",
		email:"String",
		phoneNumber:"String",
		status:"CandidateStatus"
	},
	SummaryEntry:{
		competency:"SummaryCompetency",
		numberOfQuestions:"Int",
		score:"Decimal"
	},
	SummaryCompetency:{
		uuid:"UUID",
		name:"String"
	},
	BaseTest:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		organizationName:"String",
		requiresExternalTool:"Boolean",
		description:"String",
		competencies:"Competency",
		locale:"Locale",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		mostRecentActivityAt:"DateTime",
		mostRecentActivitySecondsAgo:"Int",
		disabledAt:"DateTime",
		typeformTestId:"String",
		seniority:"Seniority",
		testState:"TestState",
		testType:"TestType",
		completedBy:"Int",
		averageSolutionTime:"Int",
		averageSolutionScore:"Decimal",
		competencyNames:"String",
		assignedTo:"BaseUser",
		solutionsDistribution:"Bin",
		experimental:"Boolean"
	},
	Response:{
		uuid:"UUID",
		index:"Int",
		question:"Question",
		answersGiven:"AnswerGiven",
		score:"Decimal",
		outdated:"Boolean"
	},
	AnswerGiven:{
		uuid:"UUID",
		answer:"Answer",
		correct:"Boolean",
		userSelected:"Boolean",
		content:"String"
	},
	AdminTest:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		organizationName:"String",
		requiresExternalTool:"Boolean",
		description:"String",
		competencies:"Competency",
		locale:"Locale",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		mostRecentActivityAt:"DateTime",
		mostRecentActivitySecondsAgo:"Int",
		disabledAt:"DateTime",
		typeformTestId:"String",
		seniority:"Seniority",
		testState:"TestState",
		testType:"TestType",
		completedBy:"Int",
		averageSolutionTime:"Int",
		averageSolutionScore:"Decimal",
		competencyNames:"String",
		assignedTo:"AdminBaseUser",
		solutionsDistribution:"Bin",
		experimental:"Boolean",
		questionsNo:"Int",
		totalTime:"Int",
		questions:"Question",
		averageSummary:"SummaryEntry",
		solutionUuids:"UUID"
	},
	CompetencyConnection:{
		pageInfo:"PageInfo",
		edges:"CompetencyEdge",
		totalCount:"Int"
	},
	CompetencyEdge:{
		node:"Competency",
		cursor:"String"
	},
	TestConnection:{
		pageInfo:"PageInfo",
		edges:"TestEdge",
		totalCount:"Int"
	},
	TestEdge:{
		node:"BaseTest",
		cursor:"String"
	},
	SolutionConnection:{
		pageInfo:"PageInfo",
		edges:"SolutionEdge",
		totalCount:"Int"
	},
	SolutionEdge:{
		node:"BaseSolution",
		cursor:"String"
	},
	BaseSolution:{
		candidate:"SolutionCandidate",
		uuid:"UUID",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		seenAt:"DateTime",
		submittedAt:"DateTime",
		testRoleName:"String",
		starred:"Boolean",
		solutionState:"SolutionState",
		score:"Decimal",
		timeSpent:"Int",
		summary:"SummaryEntry"
	},
	Test:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		organizationName:"String",
		requiresExternalTool:"Boolean",
		description:"String",
		competencies:"Competency",
		locale:"Locale",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		mostRecentActivityAt:"DateTime",
		mostRecentActivitySecondsAgo:"Int",
		disabledAt:"DateTime",
		typeformTestId:"String",
		seniority:"Seniority",
		testState:"TestState",
		testType:"TestType",
		completedBy:"Int",
		averageSolutionTime:"Int",
		averageSolutionScore:"Decimal",
		competencyNames:"String",
		assignedTo:"BaseUser",
		solutionsDistribution:"Bin",
		experimental:"Boolean",
		questions:"Question",
		averageSummary:"SummaryEntry",
		solutionUuids:"UUID"
	},
	Candidate:{
		uuid:"UUID",
		phoneNumber:"String",
		firstName:"String",
		lastName:"String",
		email:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		disabled:"Boolean",
		assessments:"Assessment",
		solutions:"BaseSolution"
	},
	Assessment:{
		candidate:"SolutionCandidate",
		uuid:"UUID",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		seenAt:"DateTime",
		submittedAt:"DateTime",
		testRoleName:"String",
		starred:"Boolean",
		solutionState:"SolutionState",
		score:"Decimal",
		timeSpent:"Int",
		summary:"SummaryEntry",
		test:"BaseTest",
		responses:"Response"
	},
	TestPreviewResult:{
		"...on TestPreview":"TestPreview",
		"...on TestPreviewUnavailable":"TestPreviewUnavailable"
	},
	TestPreview:{
		uuid:"UUID",
		title:"String",
		roleName:"String",
		availableTime:"Int",
		numberOfQuestions:"Int",
		organizationName:"String",
		requiresExternalTool:"Boolean",
		description:"String",
		competencies:"Competency",
		locale:"Locale"
	},
	TestPreviewUnavailable:{
		reason:"String"
	},
	CandidateSolutionPreview:{
		test:"TestPreview",
		solutionState:"SolutionState",
		candidate:"CandidatePreview",
		score:"Decimal",
		timeSpent:"Int",
		summary:"SummaryEntry"
	},
	CandidatePreview:{
		uuid:"UUID",
		phoneNumber:"String",
		firstName:"String",
		lastName:"String",
		email:"String"
	},
	SuggestedCompetencies:{
		selected:"SuggestedCompetency"
	},
	SuggestedCompetency:{
		uuid:"UUID",
		name:"String",
		domain:"String",
		icon:"Icon",
		description:"String"
	},
	SuggestedOpenQuestions:{
		suggested:"SuggestedOpenQuestion"
	},
	SuggestedOpenQuestion:{
		competencyName:"String",
		content:"String",
		uuid:"UUID"
	},
	MutationsRoot:{
		hero:"HeroTracking",
		test:"TestMutation",
		auth:"Auth",
		resetPassword:"ResetPassword",
		adminCreateTest:"CreateTest",
		adminUpdateTest:"UpdateTest",
		adminPublishTest:"PublishTest",
		adminDisableTest:"DisableTest",
		adminEnableTest:"EnableTest",
		adminDeleteTest:"DeleteTest",
		adminDuplicateTest:"DuplicateTest",
		adminCreateUser:"CreateUser",
		adminUpdateUser:"UpdateUser",
		adminDeleteUser:"DeleteUser",
		adminToggleBlockUser:"ToggleBlockUser",
		adminTriggerPasswordReset:"TriggerPasswordReset",
		adminImpersonateUser:"ImpersonateUser",
		candidateIssue2faToken:"Issue2FAToken",
		candidateGet2faToken:"Get2FAToken",
		candidateVerify2faToken:"Verify2FAToken",
		registerCandidate:"RegisterCandidate",
		candidateAttemptSolution:"CandidateAttemptSolution",
		candidateGiveFeedback:"CandidateGiveFeedback",
		adminCreateOpenQuestion:"CreateOpenQuestion",
		adminUpdateOpenQuestion:"UpdateOpenQuestion",
		adminDeleteOpenQuestion:"DeleteOpenQuestion",
		recruiterStarSolution:"RecruiterStarSolution",
		recruiterSeeSolution:"RecruiterSeeSolution",
		recruiterArchiveTest:"RecruiterArchiveTest",
		recruiterDeleteTest:"RecruiterDeleteTest",
		recruiterSignUp:"SignUp",
		sendResetPassword:"SendResetPassword",
		wizardCreateTest:"RecruiterWizardCreateTest",
		recruiter:"RecruiterMutation",
		candidate:"CandidateMutation"
	},
	HeroTracking:{
		result:"String"
	},
	JSONString: `scalar.JSONString` as const,
	TestMutation:{
		result:"String"
	},
	Auth:{
		authorizationToken:"String"
	},
	ResetPassword:{
		authorizationToken:"String"
	},
	CreateTest:{
		result:"AdminTest"
	},
	UpdateTest:{
		result:"AdminTest"
	},
	PublishTest:{
		result:"AdminTest"
	},
	DisableTest:{
		result:"AdminTest"
	},
	EnableTest:{
		result:"AdminTest"
	},
	DeleteTest:{
		result:"AdminTest"
	},
	DuplicateTest:{
		result:"AdminTest"
	},
	CreateUser:{
		resetToken:"String",
		user:"AdminBaseUser"
	},
	UpdateUser:{
		user:"AdminBaseUser"
	},
	DeleteUser:{
		result:"AdminBaseUser"
	},
	ToggleBlockUser:{
		result:"AdminBaseUser"
	},
	TriggerPasswordReset:{
		resetToken:"String"
	},
	ImpersonateUser:{
		authorizationToken:"String"
	},
	Issue2FAToken:{
		result:"String"
	},
	Get2FAToken:{
		intermediateToken:"String"
	},
	Verify2FAToken:{
		intermediateToken:"String"
	},
	RegisterCandidate:{
		candidateSessionToken:"String"
	},
	CandidateAttemptSolution:{
		typeformTestId:"String"
	},
	CandidateGiveFeedback:{
		result:"String"
	},
	CreateOpenQuestion:{
		uuid:"UUID",
		content:"String"
	},
	UpdateOpenQuestion:{
		uuid:"UUID",
		content:"String"
	},
	DeleteOpenQuestion:{
		result:"String"
	},
	RecruiterStarSolution:{
		result:"String"
	},
	RecruiterSeeSolution:{
		result:"String"
	},
	RecruiterArchiveTest:{
		result:"String"
	},
	RecruiterDeleteTest:{
		result:"String"
	},
	SignUp:{
		payload:"RecruiterSignUpPayload"
	},
	RecruiterSignUpPayload:{
		status:"String",
		user:"SignUpUser",
		errors:"Error"
	},
	SignUpUser:{
		email:"String",
		uuid:"UUID",
		accountId:"Int"
	},
	Error:{
		name:"String",
		messages:"String"
	},
	SendResetPassword:{
		result:"String"
	},
	RecruiterWizardCreateTest:{
		test:"WizardTest"
	},
	WizardTest:{
		id:"UUID",
		title:"String"
	},
	RecruiterMutation:{
		updateCandidatesStatus:"UpdateCandidatesStatus",
		signUp:"SignUp"
	},
	UpdateCandidatesStatus:{
		candidates:"SolutionCandidate"
	},
	CandidateMutation:{
		startSolution:"StartSolution",
		seeQuestion:"SeeQuestion",
		submitResponse:"SubmitResponse",
		submitSolution:"SubmitSolution"
	},
	StartSolution:{
		solutionAttempt:"SolutionAttempt"
	},
	SolutionAttempt:{
		solutionId:"UUID",
		questions:"TestQuestion",
		availableTime:"Int",
		suggestedTime:"Int"
	},
	TestQuestion:{
		"...on SingleChoiceQuestion":"SingleChoiceQuestion",
		"...on MultipleChoiceQuestion":"MultipleChoiceQuestion",
		"...on NumericQuestion":"NumericQuestion",
		"...on TextQuestion":"TextQuestion"
	},
	SingleChoiceQuestion:{
		uuid:"UUID",
		text:"String",
		possibleAnswers:"QuestionAnswer"
	},
	BaseTestQuestion:{
		"...on SingleChoiceQuestion": "SingleChoiceQuestion",
		"...on MultipleChoiceQuestion": "MultipleChoiceQuestion",
		"...on NumericQuestion": "NumericQuestion",
		"...on TextQuestion": "TextQuestion",
		uuid:"UUID",
		text:"String"
	},
	BaseTestQuestionWithAnswers:{
		"...on SingleChoiceQuestion": "SingleChoiceQuestion",
		"...on MultipleChoiceQuestion": "MultipleChoiceQuestion",
		possibleAnswers:"QuestionAnswer"
	},
	QuestionAnswer:{
		uuid:"UUID",
		text:"String"
	},
	MultipleChoiceQuestion:{
		uuid:"UUID",
		text:"String",
		possibleAnswers:"QuestionAnswer"
	},
	NumericQuestion:{
		uuid:"UUID",
		text:"String"
	},
	TextQuestion:{
		uuid:"UUID",
		text:"String"
	},
	SeeQuestion:{
		result:"String"
	},
	SubmitResponse:{
		result:"String"
	},
	SubmitSolution:{
		result:"String"
	}
}

export const Ops = {
query: "RootQuery" as const,
	mutation: "MutationsRoot" as const
}