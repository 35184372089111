/* eslint-disable no-console */
import React from "react"
import { ApolloClient, ApolloProvider as _ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { notification } from "antd"

import { API_URL } from "../../constants"
import { getTokenToClient } from "../../utils"

const uri = `${API_URL}/graphql`

const httpLink = new HttpLink({
    uri,
})

const authLink = setContext((_, { headers }) => {
    const token = getTokenToClient()
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    }
})

const isProduction = process.env.NODE_ENV === "production"

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message }, index) => {
            if (index > 5) {
                console.log("More errors!")
                return
            }
            if (process.browser && !isProduction) return notification.error({ message })
        })

    if (networkError) console.log(`[Network error]: ${networkError}`)
})

const client = new ApolloClient({
    uri,
    cache: new InMemoryCache({}),
    connectToDevTools: true,
    link: from([errorLink, authLink.concat(httpLink)]),
})

export const ApolloProvider = ({ children }: { children: React.ReactNode }) => {
    return <_ApolloProvider client={client}>{children}</_ApolloProvider>
}
