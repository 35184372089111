import { ZeusScalars } from "zeus"
import { typedGql } from "zeus/typedDocumentNode"

export const scalars = ZeusScalars({
    Decimal: {
        decode: (e: unknown) => parseFloat(e as string),
    },
    UUID: {
        decode: (e: unknown) => e as string,
    },
    DateTime: {
        decode: (e: unknown) => e as string,
    },
})
export const typedMutation = typedGql("mutation", { scalars })
export const typedQuery = typedGql("query", { scalars })
