import { Flex } from "@/components/common"
import { Loader } from "@/components/ui"
import { useAuth } from "@/containers/authData"
import { ImpersonatorLayout } from "@/layouts/ImpersonatorLayout"
import { paths } from "@/paths"
import { PageProps } from "@/utils/next"
import { Spin } from "antd"
import { useRouter } from "next/router"

export type ProtectedRoutingProps = {
    appUsesNewTheme?: boolean
    children: React.ReactNode
} & Pick<PageProps, "restrictredUserTypes">

export const ProtectedRouting = ({
    children,
    restrictredUserTypes,
    appUsesNewTheme = false,
}: ProtectedRoutingProps) => {
    const router = useRouter()
    const {
        isLogged,
        loadingUserData,
        userData,
        userType,
        setUrlBeforeLoginRedirect,
        urlBeforeLoginRedirect,
        isImpersonating,
    } = useAuth()

    const isRestricted =
        !loadingUserData &&
        ((isLogged === "no" &&
            userType === "candidate" &&
            restrictredUserTypes &&
            restrictredUserTypes.includes("candidate")) ||
            (isLogged === "yes" && userType === "hr" && restrictredUserTypes && restrictredUserTypes.includes("hr")))

    const loggedTryGoToOrIsInLoginPage =
        !loadingUserData && isLogged === "yes" && userData !== undefined && router.pathname.includes("/login")

    if (isRestricted) {
        router.replace(userType === "hr" ? paths.notFound.go() : paths.login.go())
        setUrlBeforeLoginRedirect(router.asPath)
    }

    if (loggedTryGoToOrIsInLoginPage) {
        if (urlBeforeLoginRedirect !== undefined) {
            router.replace(urlBeforeLoginRedirect)
        } else {
            router.replace(paths.hrTests.go())
        }
    }

    const loader = appUsesNewTheme ? (
        <Loader fullH />
    ) : (
        <Flex align="center" justify="center" minHeight="90vh" fullW>
            <Spin />
        </Flex>
    )

    return (
        <>
            {loadingUserData ||
            loggedTryGoToOrIsInLoginPage ||
            isRestricted ||
            isLogged === "unknown" ||
            (isLogged === "yes" && userData === undefined) ? (
                loader
            ) : isImpersonating ? (
                <ImpersonatorLayout>{children}</ImpersonatorLayout>
            ) : (
                children
            )}
        </>
    )
}
