import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Box, Flex, ImpersonatorBar } from "@/components/common"
import { useAuth } from "@/containers/authData"
import { IMPERSONATOR_BAR_HEIGHT } from "@/styles/utils"
import { Button } from "antd"

export const ImpersonatorLayout = ({ children }: { children: React.ReactNode }) => {
    const { t } = useTranslation("common")
    const { userData, stopImpersonating } = useAuth()

    return (
        <Flex col>
            <ImpersonatorBar>
                <Flex align="center" fullW justify="space-between">
                    <Flex>
                        <Trans
                            i18nKey="common:impersonatingUser"
                            values={{ name: userData ? `${userData.firstName} ${userData.lastName}` : "" }}
                            components={[<b style={{ marginLeft: 3 }} key={0} />]}
                        />
                    </Flex>
                    <Button onClick={stopImpersonating} type="text">
                        {t("finishImpersonating")}
                    </Button>
                </Flex>
            </ImpersonatorBar>
            <Box
                style={{
                    marginTop: IMPERSONATOR_BAR_HEIGHT,
                    height: `calc(100vh - ${IMPERSONATOR_BAR_HEIGHT}px)`,
                }}>
                {children}
            </Box>
        </Flex>
    )
}
