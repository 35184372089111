import { HR_APP_URL } from "@/constants"

export * from "./auth"
export * from "./number"
export * from "./dates"
export * from "./language"
export * from "./selectors"
export * from "./scalars"

export const getPublicTestUrl = (testId: string) => {
    return `${HR_APP_URL}/test?id=${testId}`
}

export const typeformFormUrl = (typeformTestId: string) => {
    return `https://admin.typeform.com/form/${typeformTestId}/create`
}

export function unpackQueryArray(queryArray: undefined): undefined
export function unpackQueryArray(queryArray: string[] | string): string
export function unpackQueryArray(queryArray: string[] | string | undefined): string | undefined
export function unpackQueryArray(queryArray: string[] | string | undefined): string | undefined {
    return Array.isArray(queryArray) ? queryArray[0] : queryArray
}

export const getSetPasswordHrUrl = (token: string) => {
    const setPasswordUrl = new URL(`${HR_APP_URL}/set_password`)
    setPasswordUrl.searchParams.append("resetToken", token)

    return setPasswordUrl
}
