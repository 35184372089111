export const breakpoints = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
    xxxl: 2560,
    sm2: 375,
    md2: 1024,
    lg2: 1440,
}

export type Breakpoint = keyof typeof breakpoints

export const zIndexes = {
    navbar: 1,
    overlay: 2,
    menu: 3,
    impersonatorBar: 4,
    background: 4,
    wizard: 5,
    mobileNavigation: 6,
}

export const palette = {
    primary: "#002CCC",
    paper: "#FFF",
    gray: {
        ["100"]: "#FAFAFA",
        ["200"]: "#E5E5E5",
        ["300"]: "#C4C4C4",
        ["400"]: "#A8A8A8",
        ["800"]: "#222222",
    },
    blue: {
        ["25"]: "rgba(0,44,204,0.6)",
        ["50"]: "#DCE2F9",
        ["100"]: "#F7F9FF",
        ["200"]: "#E0E5F9",
        ["300"]: "#B2BFF0",
        ["400"]: "#7C93E6",
        ["500"]: "#002CCC",
        ["600"]: "#1D1976",
    },
    green: {
        ["100"]: "#0DB757",
        ["500"]: "#C4C4C4",
    },

    custom: {
        yellowAmber: "#F8C300",
        golden: "#F0CF6C",
        lightgray: "#E5E5E5",
        gray: "#C4C4C4",
        white: "#FFF",
        black: "#000",
        lightblack: "#222222",
        lightblue: "#f0f8ff",
        pinkishOrange: "#FE7A48",
        reddishOrange: "#F85719",
        cobaltBlue: "#002CCC",
        persianGreen: "#0faa89",
        deepGreen: "#068066",
        salomonPink: "#FF9999",
        salomonPinkOpacity: "rgba(255, 153, 153 ,0.3)",
        jasmine: "#F8DE7E",
        jasmineOpacity: "rgba(248, 222, 126, 0.3)",
        lightTurquoise: "#91F2BC",
        lightTurquoiseOpacity: "rgba(145, 242, 188, 0.3)",
        greyOpacity: "rgba(34, 34, 34, 0.45)",
        greyOpacity2: "rgba(34, 34, 34, 0.85)",
        darkMint: "#39BF74",
        rubyRed: "#FF1A1A",
        pastelBlue: "#B2BFF0",
        beanRed: "#FB5A5A",
        errorRed: "#ff4d4f",
        darkgray: "#626264",
        orange: "#F85719",
        magnolia: "#F1F5FF",
    },
    success: "#299D00",
    error: "#BB0101",
    warning: "#F0CF6C",
    typography: {
        primary: "#000",
        secondary: "#4E4E4E",
        disabled: "#7E7C7C",
    },

    yellow400: "#FAE59D",
    yellow550: "#FAE59D",
    yellow600: "#CB792D",
    blue100: "#E0E5F9",
    blue400: "#91A5EB",
    blue500: "#002CCC",
    blue600: "#1D1976",
    green200: "#13C7A4",
    green300: "#C0E4DD",
    green400: "#B2E8CE",
    green500: "#70CEBC",
    green600: "#00AA89",
    green700: "#008A6F",
    black100: "#EDEDED",
    black200: "#D1D3D4",
    black300: "#A8ABB1",
    black400: "#6C6C6C",
    black500: "#3B3B3B",
    white: "#ffffff",
    dove300: "#D3D7E1",
    dove400: "#99A3BB",
    dove500: "#767C93",
    violet300: "#F7F9FF",
    violet400: "#EFF2FF",
    violet500: "#E2E8FF",
    violet600: "#D1DCFF",
    violet700: "#B7C4E4",
    violet800: "#99A2C8",
    violet900: "#7A87B4",
    red100: "#FFFAFA",
    red200: "#F7BDBD",
    red400: "#ED8585",
    red500: "#DC6565",
    red600: "#C63131",
}

export const fontSizes = {
    xs: "12px", // w candydat 16px
    sm: "14px", // w candydat 22px
    md: "20px", // w candydat 28px
    lg: "30px", // w candydat 34px
    xl: "42px", // w candydat 42px
    f12: "12px",
    f14: "14px",
    f16: "16px",
    f18: "18px",
    f22: "22px",
    f24: "24px",
    f26: "26px",
    f28: "28px",
    f34: "34px",
    f42: "42px",
}

export const fontFamily = {
    //default "Futura PT Book", sans-serif
    book: `"Futura PT Book", sans-serif`,
    standard: `"Futura PT ", sans-serif`,
    bold: `"Futura PT Bold", sans-serif`,
    medium: `"Futura PT Medium", sans-serif`,
    demi: `"Futura PT Demi", sans-serif`,
    rubik: "Rubik, sans-serif",
}

export const shadows = ["rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"]

export const theme = {
    zIndexes,
    palette,
    breakpoints,
    fontSizes,
    shadows,
    fontFamily,
}

declare module "styled-components" {
    export interface DefaultTheme {
        palette: typeof palette
        zIndexes: typeof zIndexes
        breakpoints: typeof breakpoints
        fontSizes: typeof fontSizes
        shadows: typeof shadows
        fontFamily: typeof fontFamily
    }
}
